import React, { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import LoadingSpinner from '../components/LoadingSpinner';
import ProtectedRoute from '../components/ProtectedRoute';
import HomePage from '../pages/HomePage';
import Login from '../pages/Login';
import AdminLogin from '../pages/AdminLogin';
import BookingPage from '../pages/BookingPage';

// Lazy load non-critical routes
const AIChat = lazy(() => import('../components/AIChat'));
const ChatManagement = lazy(() => import('../pages/ChatManagement'));
const AdminDashboard = lazy(() => import('../pages/AdminDashboard'));
const AdminProfile = lazy(() => import('../pages/AdminProfile'));
const TransfertsAeroport = lazy(() => import('../pages/TransfertsAeroport'));
const LocationAvecChauffeur = lazy(() => import('../pages/LocationAvecChauffeur'));
const ServiceCorporate = lazy(() => import('../pages/ServiceCorporate'));
const EvenementsSpeciaux = lazy(() => import('../pages/EvenementsSpeciaux'));
const Blog = lazy(() => import('../pages/Blog'));
const BlogPost = lazy(() => import('../pages/BlogPost'));
const MentionsLegales = lazy(() => import('../pages/MentionsLegales'));
const RGPD = lazy(() => import('../pages/RGPD'));
const PolitiqueCookies = lazy(() => import('../pages/PolitiqueCookies'));

const PageLoader = () => (
  <div className="flex items-center justify-center min-h-screen">
    <LoadingSpinner />
  </div>
);

const AppRoutes = () => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/booking" element={<BookingPage />} />
        <Route path="/transferts-aeroport" element={<TransfertsAeroport />} />
        <Route path="/location-avec-chauffeur" element={<LocationAvecChauffeur />} />
        <Route path="/service-corporate" element={<ServiceCorporate />} />
        <Route path="/evenements-speciaux" element={<EvenementsSpeciaux />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:id" element={<BlogPost />} />
        
        {/* Legal Pages */}
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/rgpd" element={<RGPD />} />
        <Route path="/politique-cookies" element={<PolitiqueCookies />} />

        {/* Protected Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedRoute requireAdmin>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/profile"
          element={
            <ProtectedRoute requireAdmin>
              <AdminProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/chat-management"
          element={
            <ProtectedRoute requireAdmin>
              <ChatManagement />
            </ProtectedRoute>
          }
        />

        {/* AI Chat */}
        <Route path="/chat" element={
          <ProtectedRoute>
            <AIChat />
          </ProtectedRoute>
        } />

        {/* Fallback route */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
