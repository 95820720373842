import React, { Fragment, useEffect, useState, useRef, lazy, Suspense } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Car, Users, Briefcase, Baby, MapPin, X, ArrowRight, ArrowLeft, Mail, Phone, User, Check, Clock, Map as MapIcon } from 'lucide-react';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { toast } from 'react-hot-toast';
import emailjs from '@emailjs/browser';
import { generateBookingNumber, formatBookingNumber } from '../utils/bookingUtils';
import { APP_CONFIG } from '../config/appConfig';
import '../styles/datepicker.css';

const MapComponent = lazy(() => import('./Map'));

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  formData: {
    pickup: string;
    dropoff: string;
    date: string;
    time: string;
    pickupPlaceId: string;
    dropoffPlaceId: string;
  };
}

interface VehicleType {
  id: string;
  name: string;
  description: string;
  capacity: number;
  luggage: number;
  pricePerKm: number;
  image: string;
}

interface CustomerInfo {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  additionalInfo: string;
  passengers: number;
  luggage: number;
}

const vehicleTypes: VehicleType[] = [
  {
    id: 'eco',
    name: 'Eco',
    description: 'Confortable et économique',
    capacity: 4,
    luggage: 2,
    pricePerKm: 1.5,
    image: '/vehicles/eco.jpg'
  },
  {
    id: 'berline',
    name: 'Berline',
    description: 'Luxe et confort premium',
    capacity: 4,
    luggage: 3,
    pricePerKm: 2.0,
    image: '/vehicles/berline.jpg'
  },
  {
    id: 'van',
    name: 'Van',
    description: 'Idéal pour les groupes',
    capacity: 7,
    luggage: 6,
    pricePerKm: 2.5,
    image: '/vehicles/van.jpg'
  }
];

export default function ReservationModal({ isOpen, onClose, formData }: ReservationModalProps) {
  const [step, setStep] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [formattedBookingNumber, setFormattedBookingNumber] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState('');
  const [price, setPrice] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [options, setOptions] = useState({
    babySeat: false,
    multipleStops: false,
    luggageService: false
  });
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    additionalInfo: '',
    passengers: 1,
    luggage: 0
  });

  useEffect(() => {
    if (!isOpen) {
      setStep(1);
      setShowConfirmation(false);
      setFormattedBookingNumber('');
      setSelectedVehicle('');
      setDistance(0);
      setDuration('');
      setPrice(0);
      setIsSubmitting(false);
      setOptions({
        babySeat: false,
        multipleStops: false,
        luggageService: false
      });
      setCustomerInfo({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        additionalInfo: '',
        passengers: 1,
        luggage: 0
      });
    }
  }, [isOpen]);

  useEffect(() => {
    if (distance > 0 && selectedVehicle) {
      calculatePrice();
    }
  }, [distance, selectedVehicle, options]);

  useEffect(() => {
    if (step === 2) {
      // Utiliser requestAnimationFrame pour s'assurer que le DOM est prêt
      requestAnimationFrame(() => {
        const dialogContent = document.querySelector('.overflow-y-auto');
        if (dialogContent) {
          dialogContent.scrollTo({
            top: 0,
            behavior: 'smooth'
          });
        }
      });
    }
  }, [step]);

  const calculatePrice = () => {
    if (!distance) return 0;

    const distanceInKm = distance / 1000;

    const basePrice = 20;
    let pricePerKm = 0;

    const selectedVehicleType = vehicleTypes.find(v => v.id === selectedVehicle);
    if (selectedVehicleType) {
      pricePerKm = selectedVehicleType.pricePerKm;
    } else {
      pricePerKm = 1.5;
    }

    let totalPrice = basePrice + (distanceInKm * pricePerKm);

    if (options.babySeat) totalPrice += 10;
    if (options.multipleStops) totalPrice += 15;
    if (options.luggageService) totalPrice += 20;

    const finalPrice = Math.round(totalPrice);
    setPrice(finalPrice);
    return finalPrice;
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleNext = () => {
    setStep(2);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      if (!customerInfo.firstName.trim()) {
        toast.error('Le prénom est requis');
        return;
      }
      if (!customerInfo.lastName.trim()) {
        toast.error('Le nom est requis');
        return;
      }
      if (!customerInfo.email.trim()) {
        toast.error('L\'email est requis');
        return;
      }
      if (!customerInfo.phone.trim()) {
        toast.error('Le téléphone est requis');
        return;
      }
      if (!customerInfo.passengers) {
        toast.error('Le nombre de passagers est requis');
        return;
      }

      const bookingNumber = generateBookingNumber();
      const formatted = formatBookingNumber(bookingNumber);
      setFormattedBookingNumber(formatted);

      const reservationData = {
        bookingNumber: formatted,
        firstName: customerInfo.firstName,
        lastName: customerInfo.lastName,
        email: customerInfo.email,
        phone: customerInfo.phone,
        pickup: {
          address: formData.pickup,
          coordinates: null
        },
        dropoff: {
          address: formData.dropoff,
          coordinates: null
        },
        scheduledDate: formData.date,
        scheduledTime: formData.time,
        passengers: parseInt(customerInfo.passengers.toString()),
        luggage: parseInt(customerInfo.luggage.toString()),
        vehicle: {
          name: vehicleTypes.find(v => v.id === selectedVehicle)?.name || selectedVehicle,
          type: selectedVehicle
        },
        price: price,
        status: 'pending',
        createdAt: serverTimestamp(),
        additionalInfo: customerInfo.additionalInfo,
        options: {
          babySeat: options.babySeat,
          multipleStops: options.multipleStops,
          luggageService: options.luggageService
        }
      };

      await addDoc(collection(db, 'bookings'), reservationData);

      try {
        await emailjs.send(
          APP_CONFIG.emailjs.serviceId,
          APP_CONFIG.emailjs.templateId,
          {
            to_name: customerInfo.firstName,
            to_email: customerInfo.email,
            booking_number: formatted,
            pickup: formData.pickup,
            dropoff: formData.dropoff,
            date: formData.date,
            time: formData.time,
            price: price,
            firstName: customerInfo.firstName,
            lastName: customerInfo.lastName,
            vehicle: vehicleTypes.find(v => v.id === selectedVehicle)?.name || '',
            passengers: customerInfo.passengers,
            luggage: customerInfo.luggage
          },
          APP_CONFIG.emailjs.publicKey
        );
      } catch (emailError) {
        console.error('Erreur lors de l\'envoi de l\'email:', emailError);
        toast.error('Erreur lors de l\'envoi de l\'email de confirmation');
      }

      setShowConfirmation(true);
      toast.success('Réservation enregistrée avec succès');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la réservation:', error);
      toast.error('Erreur lors de l\'enregistrement');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="flex flex-col h-full space-y-6">
            {/* Map Section avec effet de profondeur */}
            <div className="relative w-full h-[300px] rounded-2xl overflow-hidden shadow-lg transform hover:scale-[1.01] transition-transform duration-300">
              <Suspense fallback={
                <div className="w-full h-full flex items-center justify-center bg-gradient-to-br from-gray-900 to-black">
                  <div className="animate-pulse text-yellow-500">Chargement de la carte...</div>
                </div>
              }>
                <MapComponent
                  pickup={formData.pickup}
                  dropoff={formData.dropoff}
                  pickupPlaceId={formData.pickupPlaceId}
                  dropoffPlaceId={formData.dropoffPlaceId}
                  onDistanceChange={setDistance}
                  onDurationChange={setDuration}
                />
              </Suspense>
            </div>

            {/* Trip Details Section avec glassmorphism amélioré */}
            <div className="bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-xl rounded-2xl p-6 border border-white/10 shadow-xl">
              <div className="space-y-4">
                <div className="flex items-start space-x-4">
                  <div className="p-3 bg-gradient-to-br from-green-500/20 to-green-500/10 rounded-xl">
                    <MapPin className="w-5 h-5 text-green-500" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400 font-medium">Départ</p>
                    <p className="text-white font-medium">{formData.pickup}</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="p-3 bg-gradient-to-br from-red-500/20 to-red-500/10 rounded-xl">
                    <MapPin className="w-5 h-5 text-red-500" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-400 font-medium">Arrivée</p>
                    <p className="text-white font-medium">{formData.dropoff}</p>
                  </div>
                </div>
                {distance > 0 && duration && (
                  <div className="flex items-center justify-between mt-4 pt-4 border-t border-white/10">
                    <div className="flex items-center space-x-2">
                      <div className="p-2 bg-yellow-500/10 rounded-lg">
                        <MapIcon className="w-4 h-4 text-yellow-500" />
                      </div>
                      <span className="text-white font-medium">{(distance / 1000).toFixed(1)} km</span>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="p-2 bg-yellow-500/10 rounded-lg">
                        <Clock className="w-4 h-4 text-yellow-500" />
                      </div>
                      <span className="text-white font-medium">{duration}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Vehicle Selection avec design moderne et animations */}
            <div className="space-y-4">
              {!selectedVehicle && (
                <div className="flex items-center justify-center p-3 bg-yellow-500/10 border border-yellow-500/20 rounded-xl">
                  <div className="flex items-center space-x-2 text-yellow-500">
                    <Car className="w-5 h-5" />
                    <span>Sélectionnez un véhicule pour continuer</span>
                  </div>
                </div>
              )}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {vehicleTypes.map((vehicle) => (
                  <div
                    key={vehicle.id}
                    onClick={() => setSelectedVehicle(vehicle.id)}
                    className={`relative group cursor-pointer transform transition-all duration-300 hover:scale-[1.02] ${
                      selectedVehicle === vehicle.id
                        ? 'ring-2 ring-yellow-500 ring-offset-2 ring-offset-black'
                        : ''
                    }`}
                  >
                    <div className="relative overflow-hidden rounded-2xl bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-xl border border-white/10">
                      <div className="aspect-[4/3] relative">
                        <img
                          src={vehicle.image}
                          alt={vehicle.name}
                          className="absolute inset-0 w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                      </div>
                      <div className="p-4">
                        <h3 className="text-xl font-semibold text-white mb-1">{vehicle.name}</h3>
                        <p className="text-sm text-gray-300 mb-3">{vehicle.description}</p>
                        <div className="flex items-center justify-between text-gray-300">
                          <div className="flex items-center space-x-1">
                            <Users className="w-4 h-4" />
                            <span>{vehicle.capacity}</span>
                          </div>
                          <div className="flex items-center space-x-1">
                            <Briefcase className="w-4 h-4" />
                            <span>{vehicle.luggage}</span>
                          </div>
                        </div>
                      </div>
                      {selectedVehicle === vehicle.id && distance > 0 && (
                        <div className="absolute top-3 right-3 bg-gradient-to-r from-yellow-500 to-yellow-600 px-4 py-2 rounded-full">
                          <span className="text-black font-semibold">{price.toFixed(2)}€</span>
                        </div>
                      )}
                      {!selectedVehicle && (
                        <div className="absolute inset-0 bg-black/40 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                          <div className="bg-yellow-500 text-black font-medium px-4 py-2 rounded-lg transform -translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
                            Cliquez pour sélectionner
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Options supplémentaires avec design moderne */}
            <div className="bg-gradient-to-br from-white/10 to-white/5 backdrop-blur-xl rounded-2xl p-6 border border-white/10">
              <h4 className="text-lg font-semibold text-white mb-4">Options supplémentaires</h4>
              <div className="space-y-4">
                <label className="flex items-center justify-between p-4 rounded-xl border border-white/10 hover:bg-white/5 transition-colors cursor-pointer group">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-gradient-to-br from-purple-500/20 to-purple-500/10 rounded-xl group-hover:from-purple-500/30 group-hover:to-purple-500/20 transition-colors">
                      <Baby className="w-5 h-5 text-purple-500" />
                    </div>
                    <div>
                      <span className="text-white font-medium">Siège bébé</span>
                      <p className="text-sm text-gray-400">Pour les enfants de moins de 3 ans</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-yellow-500 font-medium">+10€</span>
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={options.babySeat}
                        onChange={(e) => setOptions({ ...options, babySeat: e.target.checked })}
                        className="w-6 h-6 rounded-lg border-2 border-white/20 bg-white/5 checked:bg-yellow-500 focus:ring-yellow-500 focus:ring-offset-0 transition-colors cursor-pointer"
                      />
                    </div>
                  </div>
                </label>

                <label className="flex items-center justify-between p-4 rounded-xl border border-white/10 hover:bg-white/5 transition-colors cursor-pointer group">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-gradient-to-br from-blue-500/20 to-blue-500/10 rounded-xl group-hover:from-blue-500/30 group-hover:to-blue-500/20 transition-colors">
                      <MapPin className="w-5 h-5 text-blue-500" />
                    </div>
                    <div>
                      <span className="text-white font-medium">Arrêts multiples</span>
                      <p className="text-sm text-gray-400">Jusqu'à 3 arrêts supplémentaires</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-yellow-500 font-medium">+15€</span>
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={options.multipleStops}
                        onChange={(e) => setOptions({ ...options, multipleStops: e.target.checked })}
                        className="w-6 h-6 rounded-lg border-2 border-white/20 bg-white/5 checked:bg-yellow-500 focus:ring-yellow-500 focus:ring-offset-0 transition-colors cursor-pointer"
                      />
                    </div>
                  </div>
                </label>

                <label className="flex items-center justify-between p-4 rounded-xl border border-white/10 hover:bg-white/5 transition-colors cursor-pointer group">
                  <div className="flex items-center space-x-4">
                    <div className="p-3 bg-gradient-to-br from-green-500/20 to-green-500/10 rounded-xl group-hover:from-green-500/30 group-hover:to-green-500/20 transition-colors">
                      <Briefcase className="w-5 h-5 text-green-500" />
                    </div>
                    <div>
                      <span className="text-white font-medium">Service bagages</span>
                      <p className="text-sm text-gray-400">Assistance pour vos bagages</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="text-yellow-500 font-medium">+20€</span>
                    <div className="relative">
                      <input
                        type="checkbox"
                        checked={options.luggageService}
                        onChange={(e) => setOptions({ ...options, luggageService: e.target.checked })}
                        className="w-6 h-6 rounded-lg border-2 border-white/20 bg-white/5 checked:bg-yellow-500 focus:ring-yellow-500 focus:ring-offset-0 transition-colors cursor-pointer"
                      />
                    </div>
                  </div>
                </label>
              </div>
            </div>

            {/* Prix total avec animation */}
            {selectedVehicle && distance > 0 && (
              <div className="bg-gradient-to-br from-yellow-500/20 to-yellow-600/20 backdrop-blur-xl rounded-2xl p-6 border border-yellow-500/20">
                <div className="flex items-center justify-between">
                  <span className="text-xl font-medium text-white">Prix total</span>
                  <span className="text-3xl font-bold text-yellow-500">{price.toFixed(2)}€</span>
                </div>
              </div>
            )}

            {/* Bouton de navigation */}
            <div className="flex justify-end pt-4">
              <button
                type="button"
                onClick={handleNext}
                disabled={!selectedVehicle}
                className="group relative inline-flex items-center px-8 py-4 rounded-xl bg-gradient-to-r from-yellow-500 to-yellow-600 text-black font-medium hover:from-yellow-600 hover:to-yellow-700 disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300"
              >
                <span className="relative">
                  Continuer
                  <ArrowRight className="inline-block ml-2 h-5 w-5 transform group-hover:translate-x-1 transition-transform" />
                </span>
              </button>
            </div>
          </div>
        );

      case 2:
        return (
          <form onSubmit={handleSubmit} className="flex flex-col h-full space-y-6">
            {/* Formulaire de contact */}
            <div className="bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-xl rounded-2xl overflow-hidden border border-white/10 shadow-2xl">
              {/* Header avec gradient */}
              <div className="relative overflow-hidden">
                <div className="absolute inset-0 bg-gradient-to-r from-yellow-500/10 via-yellow-500/5 to-transparent"></div>
                <div className="relative p-6">
                  <h3 className="text-2xl font-semibold text-white mb-2">Vos informations</h3>
                  <p className="text-gray-400">Remplissez vos coordonnées pour finaliser la réservation</p>
                </div>
              </div>

              {/* Grille des champs */}
              <div className="p-6 pt-4 space-y-8">
                {/* Nom et Prénom */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <User className="w-4 h-4 text-yellow-500" />
                      <span className="text-sm font-medium text-gray-300">Prénom</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={customerInfo.firstName}
                      onChange={(e) => setCustomerInfo({ ...customerInfo, firstName: e.target.value })}
                      className="w-full px-4 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200"
                      placeholder="John"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <User className="w-4 h-4 text-yellow-500" />
                      <span className="text-sm font-medium text-gray-300">Nom</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={customerInfo.lastName}
                      onChange={(e) => setCustomerInfo({ ...customerInfo, lastName: e.target.value })}
                      className="w-full px-4 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200"
                      placeholder="Doe"
                    />
                  </div>
                </div>

                {/* Email et Téléphone */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <Mail className="w-4 h-4 text-yellow-500" />
                      <span className="text-sm font-medium text-gray-300">Email</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={customerInfo.email}
                      onChange={(e) => setCustomerInfo({ ...customerInfo, email: e.target.value })}
                      className="w-full px-4 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200"
                      placeholder="john.doe@example.com"
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <Phone className="w-4 h-4 text-yellow-500" />
                      <span className="text-sm font-medium text-gray-300">Téléphone</span>
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      value={customerInfo.phone}
                      onChange={(e) => setCustomerInfo({ ...customerInfo, phone: e.target.value })}
                      className="w-full px-4 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200"
                      placeholder="+33 6 12 34 56 78"
                    />
                  </div>
                </div>

                {/* Passagers et Bagages */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <Users className="w-4 h-4 text-yellow-500" />
                      <span className="text-sm font-medium text-gray-300">Nombre de passagers</span>
                    </label>
                    <div className="relative flex items-center">
                      <button
                        type="button"
                        onClick={() => setCustomerInfo(prev => ({
                          ...prev,
                          passengers: Math.max(1, prev.passengers - 1)
                        }))}
                        className="absolute left-0 top-0 bottom-0 px-3 flex items-center justify-center text-gray-400 hover:text-yellow-500 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <input
                        type="number"
                        name="passengers"
                        min="1"
                        max="8"
                        value={customerInfo.passengers}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (value >= 1 && value <= 8) {
                            setCustomerInfo({ ...customerInfo, passengers: value });
                          }
                        }}
                        className="w-full px-12 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200 text-center"
                        placeholder="1"
                      />
                      <button
                        type="button"
                        onClick={() => setCustomerInfo(prev => ({
                          ...prev,
                          passengers: Math.min(8, prev.passengers + 1)
                        }))}
                        className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center text-gray-400 hover:text-yellow-500 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <Users className="absolute right-12 top-2.5 h-5 w-5 text-gray-400" />
                      {customerInfo.passengers >= 8 && (
                        <span className="absolute -bottom-5 left-0 text-xs text-yellow-500">Maximum 8 passagers</span>
                      )}
                    </div>
                  </div>
                  <div className="space-y-2">
                    <label className="flex items-center space-x-2">
                      <Briefcase className="w-4 h-4 text-yellow-500" />
                      <span className="text-sm font-medium text-gray-300">Nombre de bagages</span>
                    </label>
                    <div className="relative flex items-center">
                      <button
                        type="button"
                        onClick={() => setCustomerInfo(prev => ({
                          ...prev,
                          luggage: Math.max(0, prev.luggage - 1)
                        }))}
                        className="absolute left-0 top-0 bottom-0 px-3 flex items-center justify-center text-gray-400 hover:text-yellow-500 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <input
                        type="number"
                        name="luggage"
                        min="0"
                        max="7"
                        value={customerInfo.luggage}
                        onChange={(e) => {
                          const value = parseInt(e.target.value);
                          if (value >= 0 && value <= 7) {
                            setCustomerInfo({ ...customerInfo, luggage: value });
                          }
                        }}
                        className="w-full px-12 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200 text-center"
                        placeholder="0"
                      />
                      <button
                        type="button"
                        onClick={() => setCustomerInfo(prev => ({
                          ...prev,
                          luggage: Math.min(7, prev.luggage + 1)
                        }))}
                        className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center text-gray-400 hover:text-yellow-500 transition-colors"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                        </svg>
                      </button>
                      <Briefcase className="absolute right-12 top-2.5 h-5 w-5 text-gray-400" />
                      {customerInfo.luggage >= 7 && (
                        <span className="absolute -bottom-5 left-0 text-xs text-yellow-500">Maximum 7 bagages</span>
                      )}
                    </div>
                  </div>
                </div>

                {/* Informations supplémentaires */}
                <div className="space-y-2">
                  <label className="flex items-center space-x-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 text-yellow-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                    </svg>
                    <span className="text-sm font-medium text-gray-300">Informations supplémentaires</span>
                  </label>
                  <textarea
                    name="additionalInfo"
                    value={customerInfo.additionalInfo}
                    onChange={(e) => setCustomerInfo({ ...customerInfo, additionalInfo: e.target.value })}
                    rows={3}
                    className="w-full px-4 py-3 bg-black/20 border border-white/10 rounded-xl focus:ring-2 focus:ring-yellow-500 focus:border-transparent text-white placeholder-gray-500 transition-all duration-200 resize-none"
                    placeholder="Instructions particulières, besoins spéciaux..."
                  />
                </div>
              </div>
            </div>

            {/* Résumé de la réservation avec design amélioré */}
            <div className="bg-gradient-to-br from-gray-800 to-gray-900 rounded-xl overflow-hidden border border-gray-700 shadow-xl">
              <div className="p-6 bg-gradient-to-r from-yellow-500/10 to-yellow-600/10">
                <h3 className="text-xl font-semibold text-white mb-2">Résumé de votre réservation</h3>
                <p className="text-gray-400">Vérifiez les détails de votre trajet avant de confirmer</p>
              </div>
              
              <div className="p-6 space-y-6">
                {/* Trajet avec design amélioré */}
                <div className="relative">
                  <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gradient-to-b from-green-500 to-red-500"></div>
                  <div className="space-y-6 relative">
                    <div className="flex items-start space-x-4 pl-8">
                      <div className="absolute left-2.5 -translate-x-1/2 w-3 h-3 rounded-full bg-green-500 ring-4 ring-green-500/20"></div>
                      <div>
                        <p className="text-sm font-medium text-green-500">Point de départ</p>
                        <p className="text-white mt-1">{formData.pickup}</p>
                      </div>
                    </div>
                    <div className="flex items-start space-x-4 pl-8">
                      <div className="absolute left-2.5 -translate-x-1/2 w-3 h-3 rounded-full bg-red-500 ring-4 ring-red-500/20"></div>
                      <div>
                        <p className="text-sm font-medium text-red-500">Destination</p>
                        <p className="text-white mt-1">{formData.dropoff}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Informations du trajet en grille */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 p-4 bg-black/20 rounded-xl">
                  <div>
                    <p className="text-sm text-gray-400">Date</p>
                    <p className="text-white font-medium mt-1">{formData.date}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Heure</p>
                    <p className="text-white font-medium mt-1">{formData.time}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Distance</p>
                    <p className="text-white font-medium mt-1">{(distance / 1000).toFixed(1)} km</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-400">Durée estimée</p>
                    <p className="text-white font-medium mt-1">{duration}</p>
                  </div>
                </div>

                {/* Détails du service */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-3">
                    <div className="flex items-center space-x-3 bg-yellow-500/10 p-3 rounded-lg">
                      <Car className="w-5 h-5 text-yellow-500" />
                      <div>
                        <p className="text-white font-medium">
                          {vehicleTypes.find(v => v.id === selectedVehicle)?.name}
                        </p>
                        <p className="text-sm text-gray-400">
                          {vehicleTypes.find(v => v.id === selectedVehicle)?.description}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3 bg-blue-500/10 p-3 rounded-lg">
                      <Users className="w-5 h-5 text-blue-500" />
                      <div>
                        <p className="text-white font-medium">{customerInfo.passengers} passager(s)</p>
                        <p className="text-sm text-gray-400">Capacité maximale : {vehicleTypes.find(v => v.id === selectedVehicle)?.capacity} personnes</p>
                      </div>
                    </div>
                    <div className="flex items-center space-x-3 bg-purple-500/10 p-3 rounded-lg">
                      <Briefcase className="w-5 h-5 text-purple-500" />
                      <div>
                        <p className="text-white font-medium">{customerInfo.luggage} bagage(s)</p>
                        <p className="text-sm text-gray-400">Capacité maximale : {vehicleTypes.find(v => v.id === selectedVehicle)?.luggage} bagages</p>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-3">
                    <p className="text-sm text-gray-400 font-medium">Options sélectionnées</p>
                    {(options.babySeat || options.multipleStops || options.luggageService) ? (
                      <>
                        {options.babySeat && (
                          <div className="flex items-center space-x-3 bg-emerald-500/10 p-3 rounded-lg">
                            <Baby className="w-5 h-5 text-emerald-500" />
                            <div>
                              <p className="text-white">Siège bébé</p>
                              <p className="text-sm text-gray-400">+10€</p>
                            </div>
                          </div>
                        )}
                        {options.multipleStops && (
                          <div className="flex items-center space-x-3 bg-pink-500/10 p-3 rounded-lg">
                            <MapPin className="w-5 h-5 text-pink-500" />
                            <div>
                              <p className="text-white">Arrêts multiples</p>
                              <p className="text-sm text-gray-400">+15€</p>
                            </div>
                          </div>
                        )}
                        {options.luggageService && (
                          <div className="flex items-center space-x-3 bg-orange-500/10 p-3 rounded-lg">
                            <Briefcase className="w-5 h-5 text-orange-500" />
                            <div>
                              <p className="text-white">Service bagages</p>
                              <p className="text-sm text-gray-400">+20€</p>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <p className="text-gray-500 italic">Aucune option sélectionnée</p>
                    )}
                  </div>
                </div>

                {/* Prix total avec effet de mise en évidence */}
                <div className="mt-6 p-4 bg-gradient-to-r from-yellow-500/20 to-yellow-600/20 rounded-xl border border-yellow-500/20">
                  <div className="flex justify-between items-center">
                    <span className="text-gray-300 font-medium">Prix total</span>
                    <div className="text-right">
                      <span className="text-3xl font-bold text-yellow-500">{price}€</span>
                      <p className="text-sm text-gray-400 mt-1">TVA incluse</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Boutons de navigation */}
            <div className="flex flex-col sm:flex-row justify-between items-center gap-4 mt-8">
              <button
                type="button"
                onClick={handleBack}
                className="w-full sm:w-auto px-6 py-3 rounded-lg border border-white/10 text-white hover:bg-white/5 transition-all duration-300"
              >
                <ArrowLeft className="inline-block mr-2 h-5 w-5" />
                Retour
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full sm:w-auto flex items-center justify-center px-8 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-black font-medium rounded-lg transition-all duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isSubmitting ? (
                  <>
                    <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-black mr-3" />
                    Confirmation en cours...
                  </>
                ) : (
                  <>
                    Confirmer la réservation
                    <ArrowRight className="w-5 h-5 ml-2" />
                  </>
                )}
              </button>
            </div>
          </form>
        );

      default:
        return null;
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog 
        as="div" 
        className="relative z-[9999]" 
        onClose={onClose}
        static
      >
        <div className="fixed inset-0 z-[9999] bg-gray-500 bg-opacity-75 backdrop-blur-sm" aria-hidden="true" />

        <div className="fixed inset-0 z-[10000] overflow-y-auto modal-ios-fix">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-gradient-to-br from-gray-900 to-black text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl border border-yellow-500/20 modal-ios-fix">
                {/* Header avec effet de glassmorphism */}
                <div className="relative px-8 py-6 bg-black/40 backdrop-blur-lg border-b border-yellow-500/20">
                  <div className="absolute inset-0 bg-gradient-to-r from-yellow-500/10 via-transparent to-transparent opacity-60" />
                  <div className="relative flex justify-between items-center">
                    <Dialog.Title as="h3" className="text-2xl font-bold bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent">
                      Réserver votre trajet
                    </Dialog.Title>
                    <button
                      type="button"
                      onClick={onClose}
                      className="rounded-lg p-2 text-gray-400 hover:text-white bg-gray-800 hover:bg-gray-700 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-yellow-500/40"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                <div className="p-8">
                  {renderContent()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>

        {showConfirmation && (
          <div className="fixed inset-0 z-[10001] overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <div className="relative w-full max-w-2xl transform overflow-hidden rounded-2xl bg-gradient-to-br from-gray-900 to-black p-8 text-left shadow-2xl transition-all border border-yellow-500/20 modal-ios-fix">
                {/* Contenu de la confirmation */}
                <div className="space-y-8">
                  {/* En-tête avec icône et titre */}
                  <div className="text-center">
                    <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-gradient-to-r from-yellow-500/20 to-yellow-600/20 mb-6">
                      <Check className="h-10 w-10 text-yellow-500" />
                    </div>
                    <h3 className="text-3xl font-bold text-white mb-2">
                      Réservation confirmée !
                    </h3>
                    <p className="text-lg text-gray-400">
                      Votre numéro de réservation : <span className="font-mono text-yellow-500 font-bold">{formattedBookingNumber}</span>
                    </p>
                  </div>

                  {/* Résumé de la réservation */}
                  <div className="bg-black/40 backdrop-blur-md rounded-xl p-6 border border-yellow-500/20">
                    <h4 className="text-xl font-medium text-yellow-500 mb-6">Récapitulatif de votre trajet</h4>
                    <div className="space-y-6">
                      {/* Informations client */}
                      <div className="grid grid-cols-2 gap-4 pb-4 border-b border-gray-800">
                        <div>
                          <p className="text-sm text-gray-400">Nom</p>
                          <p className="text-white font-medium">{customerInfo.lastName}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-400">Prénom</p>
                          <p className="text-white font-medium">{customerInfo.firstName}</p>
                        </div>
                      </div>

                      {/* Détails du trajet */}
                      <div className="space-y-4">
                        <div className="flex items-start space-x-3">
                          <div className="p-2 bg-green-500/10 rounded-lg mt-1">
                            <MapPin className="w-5 h-5 text-green-500" />
                          </div>
                          <div>
                            <p className="text-sm text-gray-400">Départ</p>
                            <p className="text-white">{formData.pickup}</p>
                          </div>
                        </div>
                        <div className="flex items-start space-x-3">
                          <div className="p-2 bg-red-500/10 rounded-lg mt-1">
                            <MapPin className="w-5 h-5 text-red-500" />
                          </div>
                          <div>
                            <p className="text-sm text-gray-400">Arrivée</p>
                            <p className="text-white">{formData.dropoff}</p>
                          </div>
                        </div>
                      </div>

                      {/* Détails du service */}
                      <div className="grid grid-cols-2 gap-4 pt-4 border-t border-gray-800">
                        <div>
                          <p className="text-sm text-gray-400">Véhicule</p>
                          <p className="text-white font-medium">
                            {vehicleTypes.find(v => v.id === selectedVehicle)?.name}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-400">Date et heure</p>
                          <p className="text-white font-medium">{formData.date} à {formData.time}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-400">Passagers</p>
                          <p className="text-white font-medium">{customerInfo.passengers}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-400">Bagages</p>
                          <p className="text-white font-medium">{customerInfo.luggage}</p>
                        </div>
                      </div>

                      {/* Prix total */}
                      <div className="mt-6 p-4 bg-gradient-to-r from-yellow-500/20 to-yellow-600/20 rounded-xl">
                        <div className="flex justify-between items-center">
                          <span className="text-gray-300 font-medium">Prix total</span>
                          <span className="text-2xl font-bold text-yellow-500">{price}€</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Message de confirmation d'email */}
                  <div className="text-center text-gray-400">
                    <Mail className="inline-block w-5 h-5 mr-2 text-yellow-500" />
                    Un email de confirmation a été envoyé à {customerInfo.email}
                  </div>

                  {/* Bouton de fermeture */}
                  <div className="flex justify-center mt-6">
                    <button
                      type="button"
                      onClick={onClose}
                      className="px-8 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-black font-medium rounded-lg transition-all duration-200"
                    >
                      Fermer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
