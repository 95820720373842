import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Clock, Shield, CreditCard, Heart, Car, Plane, Building2, PartyPopper } from 'lucide-react';
import ParticleBackground from './ParticleBackground';
import { Link } from 'react-router-dom';

const benefits = [
  {
    icon: Clock,
    title: 'Service 24/7',
    description: 'Une équipe disponible jour et nuit pour répondre à vos besoins de transport, 365 jours par an.',
    stats: '24/7',
    highlight: 'Disponibilité permanente'
  },
  {
    icon: Shield,
    title: 'Satisfaction Client',
    description: 'Plus de 5000 clients satisfaits nous font confiance pour leurs déplacements premium.',
    stats: '5000+',
    highlight: 'Clients satisfaits'
  },
  {
    icon: CreditCard,
    title: 'Note Excellence',
    description: 'Une note exceptionnelle de 4.9/5 basée sur plus de 2000 avis clients vérifiés.',
    stats: '4.9/5',
    highlight: 'Avis clients'
  },
  {
    icon: Heart,
    title: 'Expérience Premium',
    description: 'Une flotte de véhicules haut de gamme et des chauffeurs expérimentés à votre service.',
    stats: '100%',
    highlight: 'Satisfaction garantie'
  }
];

const services = [
  {
    icon: Car,
    title: 'Transport VTC Premium',
    description: 'Voyagez avec style dans nos véhicules haut de gamme conduits par des chauffeurs professionnels. Service personnalisé et confort optimal garantis.',
    image: '/events/corporate.jpg.jpg',
    features: ['Véhicules luxueux', 'Chauffeurs expérimentés', 'Service sur mesure'],
    link: '/location-avec-chauffeur'
  },
  {
    icon: Plane,
    title: 'Transferts Aéroport',
    description: 'Service de navette premium vers tous les aéroports. Suivi des vols en temps réel et prise en charge de vos bagages inclus.',
    image: '/events/gala2.jpg.jpg',
    features: ['Suivi des vols', 'Aide aux bagages', 'Accueil personnalisé'],
    link: '/transferts-aeroport'
  },
  {
    icon: Building2,
    title: 'Service Corporate',
    description: 'Solutions de transport dédiées aux entreprises. Forfaits sur mesure et facturation simplifiée pour vos déplacements professionnels.',
    image: '/events/fashion.jpg.jpg',
    features: ['Facturation entreprise', 'Réservation prioritaire', 'Confort business'],
    link: '/service-corporate'
  },
  {
    icon: PartyPopper,
    title: 'Événements Spéciaux',
    description: 'Transport VIP pour vos événements : mariages, galas, soirées privées. Un service d\'exception pour vos moments inoubliables.',
    image: '/events/festival.jpg.jpg',
    features: ['Décoration sur demande', 'Coordination événement', 'Service VIP'],
    link: '/evenements-speciaux'
  }
];

export default function Services() {
  const { scrollY } = useScroll();
  const opacity = useTransform(scrollY, [0, 300], [1, 0.2]);

  return (
    <section id="services" className="relative py-20 overflow-hidden bg-[#121212]">
      {/* Particules en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none">
        <ParticleBackground />
      </div>

      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>
      
      {/* Effet de lueur */}
      <motion.div 
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm"
        animate={{
          opacity: [0.4, 1, 0.4],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Services */}
        <div className="mb-32">
          <div className="text-center mb-16">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-4xl font-bold mb-4"
            >
              <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">Nos Services</span>
              <span className="block text-2xl mt-2 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent">
                Excellence et Professionnalisme
              </span>
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-gray-400 max-w-2xl mx-auto"
            >
              Découvrez notre gamme complète de services de transport haut de gamme, conçue pour répondre à vos exigences les plus élevées.
            </motion.p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="group relative"
              >
                <div className="relative h-[450px] rounded-xl overflow-hidden bg-[#1E1E1E]/80 backdrop-blur-xl">
                  {/* Image de fond */}
                  <div className="relative h-full overflow-hidden transition-all duration-500 group-hover:h-[180px]">
                    <motion.img
                      src={service.image}
                      alt={service.title}
                      className="absolute inset-0 w-full h-full object-cover"
                      whileHover={{ scale: 1.1 }}
                      transition={{ duration: 0.7 }}
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-[#1E1E1E] via-black/50 to-transparent"></div>
                    
                    {/* Titre toujours visible sur l'image */}
                    <div className="absolute bottom-0 left-0 right-0 p-6">
                      <div className="flex items-center">
                        <div className="p-2 bg-yellow-500/10 backdrop-blur-sm rounded-lg">
                          <service.icon className="w-6 h-6 text-yellow-500" />
                        </div>
                        <h3 className="text-xl font-bold text-white ml-3 group-hover:text-yellow-500 transition-colors">
                          {service.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                  
                  {/* Contenu qui apparaît au hover */}
                  <div className="absolute inset-x-0 bottom-0 p-6 translate-y-full transition-transform duration-500 ease-in-out group-hover:translate-y-0 bg-[#1E1E1E]/95 backdrop-blur-sm">
                    <p className="text-gray-400 text-sm mb-4">
                      {service.description}
                    </p>
                    
                    {/* Features */}
                    <div className="space-y-2 mb-4">
                      {service.features.map((feature, idx) => (
                        <div key={idx} className="flex items-center text-sm text-gray-300">
                          <div className="w-1.5 h-1.5 rounded-full bg-yellow-500 mr-2"></div>
                          {feature}
                        </div>
                      ))}
                    </div>
                    
                    {/* Bouton */}
                    <Link to={service.link}>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full px-6 py-2 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg font-medium 
                                 group-hover:shadow-[0_0_20px_rgba(234,179,8,0.3)] transition-shadow"
                      >
                        En savoir plus
                      </motion.button>
                    </Link>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Section Statistiques */}
        <div>
          <div className="text-center mb-16">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-4xl font-bold mb-4"
            >
              <span className="bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">Notre Excellence</span>
              <span className="block text-2xl mt-2 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent">
                Des chiffres qui parlent d'eux-mêmes
              </span>
            </motion.h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {benefits.map((benefit, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="relative group"
              >
                <div className="p-6 rounded-xl bg-[#1E1E1E]/80 backdrop-blur-xl border border-yellow-500/20 
                              hover:border-yellow-500/50 transition-all duration-300 h-[240px] 
                              hover:bg-[#1E1E1E]/95 hover:shadow-[0_0_30px_rgba(234,179,8,0.15)]">
                  {/* En-tête avec icône et titre */}
                  <div className="flex items-center mb-4">
                    <div className="p-3 bg-yellow-500/10 rounded-lg group-hover:bg-yellow-500/20 transition-colors">
                      <benefit.icon className="w-6 h-6 text-yellow-500" />
                    </div>
                    <h4 className="text-lg font-bold text-white ml-3">{benefit.title}</h4>
                  </div>

                  {/* Statistique principale */}
                  <div className="mb-3">
                    <div className="text-3xl font-bold bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent 
                                  group-hover:scale-110 transform transition-transform">
                      {benefit.stats}
                    </div>
                    <div className="text-sm text-yellow-500/80">{benefit.highlight}</div>
                  </div>

                  {/* Description */}
                  <p className="text-gray-400 text-sm">
                    {benefit.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
