import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStore } from '../store/authStore';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAdmin?: boolean;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requireAdmin = false }) => {
  const { user, loading } = useAuthStore();
  const location = useLocation();

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
      </div>
    );
  }

  if (!user) {
    // Rediriger vers la page de connexion appropriée
    const loginPath = requireAdmin ? '/admin/login' : '/login';
    return <Navigate to={loginPath} state={{ from: location }} replace />;
  }

  // Vérifier les permissions d'administrateur si nécessaire
  if (requireAdmin) {
    const isAdmin = user.email?.endsWith('@elitetransfert.com');
    if (!isAdmin) {
      console.log('Accès refusé: utilisateur non admin', user.email);
      return <Navigate to="/admin/login" replace />;
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;
