import React, { useEffect } from 'react';
import { MapPin } from 'lucide-react';
import usePlacesAutocomplete, { getGeocode, getLatLng } from 'use-places-autocomplete';

interface LocationInputProps {
  label: string;
  placeholder: string;
  value?: string;
  onLocationSelect: (location: { address: string; coordinates: [number, number] }) => void;
}

export default function LocationInput({ label, placeholder, value, onLocationSelect }: LocationInputProps) {
  console.log('LocationInput - Render:', { label, value });

  const {
    ready,
    value: inputValue,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: { 
      componentRestrictions: { country: 'fr' },
      language: 'fr'
    },
    debounce: 300,
    defaultValue: value,
  });

  console.log('Places API Status:', { ready, status, suggestionsCount: data.length });

  useEffect(() => {
    if (value) {
      console.log('Setting initial value:', value);
      setValue(value, false);
    }
  }, [value, setValue]);

  const handleSelect = async (address: string) => {
    console.log('Selected address:', address);
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      console.log('Geocode results:', results);
      const { lat, lng } = await getLatLng(results[0]);
      console.log('Coordinates:', { lat, lng });
      onLocationSelect({
        address,
        coordinates: [lng, lat],
      });
    } catch (error) {
      console.error('Erreur de géocodage:', error);
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log('Input changed:', e.target.value);
    setValue(e.target.value);
  };

  return (
    <div className="relative w-full">
      {label && (
        <label className="block text-sm font-bold text-gray-900 mb-2">
          {label}
        </label>
      )}
      <div className="relative">
        <MapPin className="absolute left-3 top-3 text-blue-600" />
        <input
          value={inputValue}
          onChange={handleInput}
          disabled={!ready}
          required
          placeholder={placeholder}
          className="w-full pl-10 pr-3 py-3 rounded-lg border-2 border-gray-200 focus:border-blue-500 focus:ring-2 focus:ring-blue-200 text-gray-900 font-medium placeholder:text-gray-400 transition-all duration-200"
        />
      </div>
      {status === 'OK' && (
        <ul className="absolute z-10 w-full bg-white mt-1 rounded-lg shadow-xl border border-gray-200">
          {data.map(({ place_id, description }) => (
            <li
              key={place_id}
              onClick={() => handleSelect(description)}
              className="px-4 py-3 hover:bg-gray-50 cursor-pointer text-gray-700 font-medium"
            >
              {description}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}