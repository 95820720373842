import { useState, Suspense } from 'react';
import { Calendar, Clock, Plane, MapPin, Car, Info } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useBookingStore } from '../store/bookingStore';
import VehicleSelector from './VehicleSelector';
import LocationInput from './LocationInput';
import BookingConfirmationModal from './BookingConfirmationModal';
import toast from 'react-hot-toast';
import { lazy } from 'react';

const MapComponent = lazy(() => import('./Map'));

const vehicles = [
  {
    id: 'eco',
    name: 'Eco',
    description: 'Berline confortable et économique',
    image: '/images/cars/eco.jpg',
    price: 45,
    features: ['4 passagers', 'Climatisation', '2 bagages']
  },
  {
    id: 'berline',
    name: 'Berline',
    description: 'Berline luxueuse haut de gamme',
    image: '/images/cars/berline.jpg',
    price: 65,
    features: ['4 passagers', 'Climatisation', '3 bagages', 'WiFi']
  },
  {
    id: 'van',
    name: 'Van',
    description: 'Van spacieux pour groupes',
    image: '/images/cars/van.jpg',
    price: 85,
    features: ['7 passagers', 'Climatisation', '7 bagages', 'WiFi']
  }
];

export default function BookingForm() {
  console.log('BookingForm - Render');
  
  const [showAirports, setShowAirports] = useState(false);
  const [selectedAirport, setSelectedAirport] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [scheduledDate, setScheduledDate] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState<string | null>(null);
  const [showTooltip, setShowTooltip] = useState('');

  const { 
    pickup, 
    dropoff,
    price,
    setPickup,
    setDropoff
  } = useBookingStore();

  const minDate = new Date().toISOString().split('T')[0];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Soumission du formulaire de réservation');

    if (!pickup || !dropoff || !selectedVehicle || !scheduledDate || !scheduledTime) {
      console.error('Erreur: Champs manquants');
      toast.error('Veuillez remplir tous les champs requis');
      return;
    }

    console.log('Données de réservation:', {
      pickup,
      dropoff,
      scheduledDate,
      scheduledTime,
      selectedVehicle,
    });

    try {
      // Calculer le prix en fonction de la distance et du véhicule
      const vehicle = vehicles.find(v => v.id === selectedVehicle);
      const basePrice = vehicle ? vehicle.price : 0;
      const calculatedPrice = Math.round(basePrice);

      console.log('Prix calculé:', {
        basePrice,
        calculatedPrice
      });

      console.log('BookingForm - All fields valid, showing confirmation modal', {
        pickup,
        dropoff,
        selectedVehicle,
        scheduledDate,
        scheduledTime
      });
      setShowConfirmationModal(true);
    } catch (error) {
      console.error('Erreur lors de la réservation:', error);
      toast.error('Une erreur est survenue lors de la réservation');
    }
  };

  const handleLocationSelect = (type: 'pickup' | 'dropoff', location: { address: string; coordinates: [number, number] }) => {
    console.log(`Sélection de l'adresse ${type}:`, location);
    
    if (type === 'pickup') {
      setPickup(location);
    } else {
      setDropoff(location);
    }
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* Formulaire */}
        <div className="space-y-6 p-6 lg:p-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Points de départ et d'arrivée */}
            <div className="space-y-4">
              <div className="relative">
                <LocationInput
                  icon={<MapPin className="h-5 w-5 text-yellow-500" />}
                  label="Point de départ"
                  placeholder="Entrez votre adresse de départ"
                  value={pickup?.address || ''}
                  onChange={(location) => handleLocationSelect('pickup', location)}
                  className="bg-black/50 border-gray-800 focus:border-yellow-500/50 text-gray-200"
                />
              </div>

              <div className="relative mt-4">
                <LocationInput
                  icon={<Plane className="h-5 w-5 text-yellow-500" />}
                  label="Point d'arrivée"
                  placeholder="Entrez votre destination"
                  value={dropoff?.address || ''}
                  onChange={(location) => handleLocationSelect('dropoff', location)}
                  className="bg-black/50 border-gray-800 focus:border-yellow-500/50 text-gray-200"
                />
              </div>
            </div>

            {/* Date et Heure */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Date
                </label>
                <div className="relative">
                  <input
                    type="date"
                    min={minDate}
                    value={scheduledDate}
                    onChange={(e) => setScheduledDate(e.target.value)}
                    className="w-full px-4 py-2.5 bg-black/50 border border-gray-800 rounded-lg focus:ring-2 focus:ring-yellow-500/40 focus:border-yellow-500/50 text-gray-200"
                  />
                  <Calendar className="absolute right-3 top-2.5 h-5 w-5 text-yellow-500" />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Heure
                </label>
                <div className="relative">
                  <input
                    type="time"
                    value={scheduledTime}
                    onChange={(e) => setScheduledTime(e.target.value)}
                    className="w-full px-4 py-2.5 bg-black/50 border border-gray-800 rounded-lg focus:ring-2 focus:ring-yellow-500/40 focus:border-yellow-500/50 text-gray-200"
                  />
                  <Clock className="absolute right-3 top-2.5 h-5 w-5 text-yellow-500" />
                </div>
              </div>
            </div>

            {/* Sélection du véhicule */}
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-3">
                Sélectionnez votre véhicule
              </label>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                {vehicles.map((vehicle) => (
                  <motion.button
                    key={vehicle.id}
                    type="button"
                    onClick={() => setSelectedVehicle(vehicle.id)}
                    className={`relative p-4 rounded-lg border ${
                      selectedVehicle === vehicle.id
                        ? 'border-yellow-500 bg-yellow-500/10'
                        : 'border-gray-800 bg-black/50 hover:border-yellow-500/50'
                    } transition-all group`}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <div className="text-center">
                      <Car className={`w-8 h-8 mx-auto mb-2 ${
                        selectedVehicle === vehicle.id ? 'text-yellow-500' : 'text-gray-400 group-hover:text-yellow-500'
                      }`} />
                      <h4 className={`font-medium mb-1 ${
                        selectedVehicle === vehicle.id ? 'text-yellow-500' : 'text-gray-300'
                      }`}>
                        {vehicle.name}
                      </h4>
                      <p className="text-sm text-gray-400">{vehicle.price}€/h</p>
                    </div>
                    <AnimatePresence>
                      {selectedVehicle === vehicle.id && (
                        <motion.div
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.5 }}
                          className="absolute -top-2 -right-2 w-6 h-6 bg-yellow-500 rounded-full flex items-center justify-center"
                        >
                          <Info className="w-4 h-4 text-black" />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.button>
                ))}
              </div>
            </div>

            {/* Bouton de soumission */}
            <div className="pt-4">
              <button
                type="submit"
                className="w-full px-6 py-3 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg font-medium hover:from-yellow-600 hover:to-yellow-700 transition-all shadow-lg hover:shadow-yellow-500/20"
              >
                Confirmer la réservation
              </button>
            </div>
          </form>
        </div>

        {/* Carte */}
        <div className="relative h-[400px] lg:h-auto rounded-lg overflow-hidden">
          <Suspense fallback={
            <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
              <div className="text-yellow-500 animate-pulse">Chargement de la carte...</div>
            </div>
          }>
            <MapComponent className="w-full h-full" />
          </Suspense>
        </div>
      </div>

      {showConfirmationModal && (
        <BookingConfirmationModal
          isOpen={showConfirmationModal}
          onClose={() => setShowConfirmationModal(false)}
          bookingDetails={{
            pickup: pickup?.address || '',
            dropoff: dropoff?.address || '',
            date: scheduledDate,
            time: scheduledTime,
            vehicle: selectedVehicle,
            price: price
          }}
        />
      )}
    </div>
  );
}