import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, HelpCircle } from 'lucide-react';
import ParticleBackground from './ParticleBackground';

const faqData = [
  {
    question: "Comment fonctionne la réservation de voiture avec chauffeur ?",
    answer: "La réservation est simple : sélectionnez votre lieu de départ et d'arrivée, choisissez la date et l'heure, sélectionnez le type de véhicule qui vous convient, et complétez vos informations. Vous recevrez une confirmation immédiate par email."
  },
  {
    question: "Quels types de véhicules proposez-vous ?",
    answer: "Nous proposons une gamme complète de véhicules haut de gamme : berlines de luxe, SUV, vans et minibus. Tous nos véhicules sont récents, climatisés et entretenus régulièrement pour votre confort et sécurité."
  },
  {
    question: "Les prix affichés sont-ils définitifs ?",
    answer: "Oui, nos prix sont tout compris, sans frais cachés. Le prix affiché inclut le véhicule, le chauffeur, le carburant et les taxes."
  },
  {
    question: "Comment puis-je payer ?",
    answer: "Nous acceptons les paiements par carte bancaire (Visa, Mastercard, American Express) et les virements bancaires pour les réservations professionnelles."
  },
  {
    question: "Que faire en cas de retard de vol ?",
    answer: "Pas d'inquiétude ! Nous surveillons les horaires des vols et ajustons notre service en conséquence, sans frais supplémentaires. Votre chauffeur vous attendra."
  }
];

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <section className="relative py-20 bg-[#121212] overflow-hidden">
      {/* Particules en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none">
        <ParticleBackground />
      </div>

      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>

      {/* Effet de lueur */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.4, 1, 0.4] }}
        transition={{ duration: 3, repeat: Infinity }}
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm pointer-events-none"
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            className="inline-block mb-4"
          >
            <div className="relative">
              <HelpCircle className="w-16 h-16 text-yellow-500" strokeWidth={1.5} />
              <motion.div
                className="absolute inset-0"
                animate={{ 
                  scale: [1, 1.2, 1],
                  opacity: [0.5, 0, 0.5]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <HelpCircle className="w-16 h-16 text-yellow-500" strokeWidth={1.5} />
              </motion.div>
            </div>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600 mb-4"
          >
            Questions Fréquentes
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 max-w-2xl mx-auto"
          >
            Trouvez rapidement les réponses à vos questions sur nos services de transport haut de gamme
          </motion.p>
        </div>

        <div className="max-w-3xl mx-auto space-y-6">
          {faqData.map((item, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="relative group">
                {/* Effet de lueur au hover */}
                <div className="absolute -inset-px rounded-xl bg-gradient-to-r from-yellow-500/50 via-yellow-500/50 to-yellow-600/50 opacity-0 group-hover:opacity-100 transition-opacity blur-sm"></div>
                
                <div className="relative">
                  <button
                    onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                    className="w-full text-left p-6 rounded-xl bg-[#1E1E1E]/80 backdrop-blur-xl border border-yellow-500/20 hover:border-yellow-500/50 transition-all flex justify-between items-center"
                  >
                    <span className="text-gray-200 font-medium pr-8">{item.question}</span>
                    <motion.div
                      animate={{ rotate: activeIndex === index ? 180 : 0 }}
                      transition={{ duration: 0.2 }}
                      className="text-yellow-500 flex-shrink-0"
                    >
                      <ChevronDown className="w-5 h-5" />
                    </motion.div>
                  </button>
                  
                  <AnimatePresence>
                    {activeIndex === index && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="overflow-hidden"
                      >
                        <div className="p-6 bg-[#1E1E1E]/50 backdrop-blur-sm rounded-b-xl border-x border-b border-yellow-500/20">
                          <p className="text-gray-400 leading-relaxed">{item.answer}</p>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
