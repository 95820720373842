import React from 'react';
import { motion } from 'framer-motion';
import { Plane, Building2, MapPin, Car, Book, Award, UserCheck, Briefcase, Building, Check, ArrowRight } from 'lucide-react';
import ParticleBackground from './ParticleBackground';
import { Link } from 'react-router-dom';

const airports = [
  {
    name: "Paris Charles de Gaulle",
    description: "Principal aéroport international de Paris",
    icon: Plane,
    bgImage: "/images/airports/cdg.jpg"
  },
  {
    name: "Paris Orly",
    description: "Deuxième aéroport international de Paris",
    icon: Building2,
    bgImage: "/images/airports/orly.jpg"
  },
  {
    name: "Paris Beauvais",
    description: "Aéroport desservant le nord de Paris",
    icon: Car,
    bgImage: "/images/airports/beauvais.jpg"
  },
  {
    name: "Paris Le Bourget",
    description: "Premier aéroport d'affaires d'Europe",
    icon: MapPin,
    bgImage: "/images/airports/bourget.jpg"
  }
];

const features = [
  {
    title: "Transferts VIP",
    description: "Service de transport haut de gamme avec chauffeur privé. Véhicules luxueux et confort optimal pour vos déplacements.",
    icon: Car,
    details: [
      "Véhicules premium",
      "Chauffeurs expérimentés",
      "Service ponctuel 24/7"
    ]
  },
  {
    title: "Conciergerie",
    description: "Service de conciergerie personnalisé pour répondre à tous vos besoins pendant votre séjour à Paris.",
    icon: UserCheck,
    details: [
      "Réservations restaurants",
      "Organisation d'événements",
      "Services sur mesure"
    ]
  },
  {
    title: "Bagages",
    description: "Prise en charge complète de vos bagages de l'aéroport à votre destination finale en toute sécurité.",
    icon: Briefcase,
    details: [
      "Suivi en temps réel",
      "Manipulation soignée",
      "Service express"
    ]
  },
  {
    title: "Service Business",
    description: "Solutions adaptées aux professionnels avec services dédiés pour optimiser vos déplacements d'affaires.",
    icon: Building,
    details: [
      "Salons privés",
      "WiFi embarqué",
      "Facturation entreprise"
    ]
  }
];

const Destinations = () => {
  return (
    <section id="destinations" className="relative py-20 overflow-hidden bg-[#121212]">
      {/* Particules en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none">
        <ParticleBackground />
      </div>

      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>
      
      {/* Effet de lueur */}
      <motion.div 
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[90%] md:w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm"
        animate={{
          opacity: [0.4, 1, 0.4],
        }}
        transition={{
          duration: 3,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Section Aéroports */}
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center text-[#E5C13B]"
        >
          Aéroports Desservis
        </motion.h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 mb-12 md:mb-16">
          {airports.map((airport, index) => (
            <motion.div
              key={airport.name}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative group rounded-lg overflow-hidden h-48 md:h-64"
            >
              {/* Image de fond */}
              <img
                src={airport.bgImage}
                alt={airport.name}
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                loading="lazy"
              />
              
              {/* Overlay avec flou et gradient */}
              <div className="absolute inset-0 bg-black/30 backdrop-blur-sm group-hover:bg-black/20 transition-all duration-300"></div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent"></div>
              
              {/* Contenu */}
              <div className="absolute inset-0 flex flex-col items-center justify-center p-4 md:p-6">
                <div className="bg-black/20 backdrop-blur-sm p-3 md:p-4 rounded-lg transform transition-all duration-300 group-hover:scale-105">
                  <airport.icon className="w-8 h-8 md:w-12 md:h-12 text-[#E5C13B] mx-auto mb-2 md:mb-4" />
                  <h4 className="text-lg md:text-xl font-semibold text-white text-center mb-1 md:mb-2">{airport.name}</h4>
                  <p className="text-gray-300 text-center text-xs md:text-sm">{airport.description}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Section Caractéristiques */}
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center text-[#E5C13B]"
        >
          Nos Services
        </motion.h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-12 md:mb-16">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="backdrop-blur-sm rounded-lg p-6 md:p-8 transition-all duration-300 border border-white/5 hover:border-[#E5C13B]/20 group hover:backdrop-blur-md"
            >
              <div className="flex items-start space-x-4">
                <div className="bg-white/5 backdrop-blur-md p-3 md:p-4 rounded-lg group-hover:bg-[#E5C13B]/5">
                  <feature.icon className="w-8 h-8 md:w-12 md:h-12 text-[#E5C13B] transition-transform duration-300 group-hover:scale-110" />
                </div>
                <div className="flex-1">
                  <h4 className="text-lg md:text-xl font-semibold mb-2 text-[#E5C13B] group-hover:text-[#E5C13B]/90">{feature.title}</h4>
                  <p className="text-white/80 mb-4 group-hover:text-white/90">{feature.description}</p>
                  <ul className="space-y-2">
                    {feature.details.map((detail, idx) => (
                      <li key={idx} className="flex items-center text-white/70">
                        <Check className="w-4 h-4 text-[#E5C13B]/80 mr-2" />
                        <span className="group-hover:text-white/80 transition-colors duration-300">{detail}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Section Articles Services */}
        <motion.h3
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl md:text-4xl font-bold text-center mb-6 md:mb-12 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent"
        >
          Nos Services Premium
        </motion.h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mb-12 md:mb-16">
          <Link to="/location-avec-chauffeur">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-black/50 backdrop-blur-lg rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 border border-gray-800/50 hover:border-gray-700 group"
            >
              <div className="relative h-48 md:h-64">
                <img
                  src="/images/blog/vtc-luxury.jpg"
                  alt="Services VTC Premium"
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent group-hover:opacity-80 transition-all duration-300"></div>
                <div className="absolute bottom-0 left-0 right-0 p-4 md:p-6">
                  <h4 className="text-lg md:text-2xl font-bold text-[#E5C13B] mb-2 md:mb-3">Services VTC Premium</h4>
                  <p className="text-gray-300">
                    Découvrez nos services VTC haut de gamme, avec une flotte de véhicules luxueux et des chauffeurs professionnels pour tous vos déplacements.
                  </p>
                  <span className="inline-block text-[#E5C13B] group-hover:text-[#F5D13B] transition-colors duration-300 mt-4">
                    En savoir plus →
                  </span>
                </div>
              </div>
            </motion.div>
          </Link>

          <Link to="/transferts-aeroport">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-black/50 backdrop-blur-lg rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 border border-gray-800/50 hover:border-gray-700 group"
            >
              <div className="relative h-48 md:h-64">
                <img
                  src="/images/blog/airport-guide.jpg"
                  alt="Services de Transferts Aéroports"
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent group-hover:opacity-80 transition-all duration-300"></div>
                <div className="absolute bottom-0 left-0 right-0 p-4 md:p-6">
                  <h4 className="text-lg md:text-2xl font-bold text-[#E5C13B] mb-2 md:mb-3">Services de Transferts Aéroports</h4>
                  <p className="text-gray-300">
                    Profitez de nos services de transferts premium vers tous les aéroports parisiens. Confort, ponctualité et service personnalisé garantis.
                  </p>
                  <span className="inline-block text-[#E5C13B] group-hover:text-[#F5D13B] transition-colors duration-300 mt-4">
                    En savoir plus →
                  </span>
                </div>
              </div>
            </motion.div>
          </Link>
        </div>

        {/* Section Engagement Qualité */}
        <div className="relative text-center">
          <div className="absolute inset-0 bg-[#E5C13B]/5 blur-3xl rounded-full"></div>
          <motion.h3 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-bold mb-6 text-transparent bg-clip-text bg-gradient-to-r from-[#E5C13B] to-[#E5C13B]/70 relative inline-block"
          >
            Notre Engagement Qualité
          </motion.h3>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="relative"
          >
            <p className="text-white/90 text-lg max-w-3xl mx-auto leading-relaxed mb-8">
              Nous nous engageons à fournir un service de transport premium, où chaque détail est pensé 
              pour votre confort et votre tranquillité d'esprit. Notre équipe dévouée assure une 
              expérience de voyage exceptionnelle, de votre prise en charge à votre destination.
            </p>
            <div className="flex justify-center gap-8 text-center">
              <div className="backdrop-blur-sm rounded-lg p-4 border border-white/5">
                <div className="text-[#E5C13B] text-2xl font-bold mb-1">24/7</div>
                <div className="text-white/70">Service Disponible</div>
              </div>
              <div className="backdrop-blur-sm rounded-lg p-4 border border-white/5">
                <div className="text-[#E5C13B] text-2xl font-bold mb-1">15min</div>
                <div className="text-white/70">Temps de Réponse</div>
              </div>
              <div className="backdrop-blur-sm rounded-lg p-4 border border-white/5">
                <div className="text-[#E5C13B] text-2xl font-bold mb-1">100%</div>
                <div className="text-white/70">Satisfaction Client</div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Destinations;
