import React, { useState, useRef, useEffect } from 'react';
import { Send, X, MessageCircle, ChevronDown, Loader2, User, Mail, Headphones } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { collection, addDoc, serverTimestamp, query, onSnapshot, where, getDocs, updateDoc, doc, increment } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { v4 as uuidv4 } from 'uuid';

interface Message {
  id: string;
  content: string;
  isBot: boolean;
  timestamp: any;
}

interface UserInfo {
  name: string;
  email: string;
}

const Chat: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfo>({ name: '', email: '' });
  const [formError, setFormError] = useState('');
  const [sessionId] = useState(uuidv4());
  const messagesEndRef = useRef<HTMLDivElement>(null);

  // Constantes pour le chat
  const AGENT_NAME = "Marie";

  const initialMessage = {
    id: uuidv4(),
    content: "Bonjour ! Je suis Marie, votre conseillère Helite. Je suis là pour vous accompagner dans vos réservations et répondre à toutes vos questions. Comment puis-je vous aider aujourd'hui ?",
    isBot: true,
    timestamp: new Date(),
  };

  useEffect(() => {
    console.log('UserInfo changed:', userInfo);
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo.name || !userInfo.email) return;

    // Créer une requête simple sans orderBy pour éviter le besoin d'index
    const q = query(
      collection(db, 'chat_messages'),
      where('sessionId', '==', sessionId)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const newMessages = snapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .sort((a: any, b: any) => {
          // Tri côté client
          if (!a.timestamp || !b.timestamp) return 0;
          return a.timestamp.seconds - b.timestamp.seconds;
        }) as Message[];
      
      setMessages(newMessages);
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [sessionId, userInfo]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const name = userInfo.name.trim();
    const email = userInfo.email.trim();

    if (!name || !email) {
      setFormError('Veuillez remplir tous les champs');
      return;
    }

    if (!email.includes('@')) {
      setFormError('Veuillez entrer une adresse email valide');
      return;
    }

    try {
      const timestamp = serverTimestamp();
      // Créer une nouvelle conversation
      await addDoc(collection(db, 'conversations'), {
        sessionId,
        userName: name,
        userEmail: email,
        lastMessage: initialMessage.content,
        lastMessageTimestamp: timestamp,
        status: 'active',
        agentName: AGENT_NAME,
        agentRole: 'Conseillère Helite',
        messagesCount: 1,
        createdAt: timestamp,
        updatedAt: timestamp
      });

      // Ajouter le message initial
      await addDoc(collection(db, 'chat_messages'), {
        ...initialMessage,
        sessionId,
        status: 'unread',
        userName: name,
        userEmail: email,
        agentName: AGENT_NAME,
        agentRole: 'Conseillère Helite',
        timestamp: timestamp
      });

      setIsAuthenticated(true);
      setFormError('');
    } catch (error) {
      console.error('Erreur lors de la création de la conversation:', error);
      setFormError('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const currentUserInfo = userInfo;
    if (!currentUserInfo.name || !currentUserInfo.email) {
      console.error('User info is missing');
      return;
    }

    const messageText = newMessage.trim();
    if (!messageText) {
      return;
    }

    setNewMessage('');
    setIsTyping(true);

    try {
      const timestamp = serverTimestamp();
      // Ajouter le message de l'utilisateur
      await addDoc(collection(db, 'chat_messages'), {
        content: messageText,
        isBot: false,
        timestamp: timestamp,
        sessionId,
        status: 'unread',
        userName: currentUserInfo.name,
        userEmail: currentUserInfo.email,
        agentName: AGENT_NAME,
        agentRole: 'Conseillère Helite'
      });

      // Mettre à jour la conversation
      const conversationsRef = collection(db, 'conversations');
      const q = query(conversationsRef, where('sessionId', '==', sessionId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const conversationDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, 'conversations', conversationDoc.id), {
          lastMessage: messageText,
          lastMessageTimestamp: timestamp,
          updatedAt: timestamp,
          messagesCount: increment(1)
        });
      }

      // Appel à l'API ChatGPT
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer 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'
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: 'system',
              content: 'Vous êtes un assistant de réservation de taxi professionnel. Aidez les clients avec leurs questions sur les services de transport, les réservations et les tarifs.'
            },
            {
              role: 'user',
              content: messageText
            }
          ],
          temperature: 0.7,
          max_tokens: 150
        })
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        throw new Error(`API Error: ${response.status} - ${errorData?.error?.message || response.statusText}`);
      }

      const data = await response.json();
      
      if (!data.choices || !data.choices[0] || !data.choices[0].message) {
        throw new Error('Invalid response format from API');
      }

      await handleBotResponse(data.choices[0].message.content.trim());
    } catch (error) {
      console.error('Erreur:', error);
      let errorMessage = "Désolé, je rencontre des difficultés techniques. Veuillez réessayer plus tard.";
      
      if (error instanceof Error && error.message.includes('API Error: 401')) {
        errorMessage = "Erreur d'authentification avec l'API. Veuillez vérifier la configuration.";
      }
      
      await handleBotResponse(errorMessage);
    } finally {
      setIsTyping(false);
    }
  };

  const handleBotResponse = async (content: string) => {
    const currentUserInfo = userInfo;
    if (!currentUserInfo.name || !currentUserInfo.email) {
      console.error('User info is missing');
      return;
    }

    try {
      const timestamp = serverTimestamp();
      await addDoc(collection(db, 'chat_messages'), {
        content,
        isBot: true,
        timestamp: timestamp,
        sessionId,
        status: 'unread',
        userName: currentUserInfo.name,
        userEmail: currentUserInfo.email,
        agentName: AGENT_NAME,
        agentRole: 'Conseillère Helite'
      });

      // Mettre à jour la conversation
      const conversationsRef = collection(db, 'conversations');
      const q = query(conversationsRef, where('sessionId', '==', sessionId));
      const querySnapshot = await getDocs(q);
      
      if (!querySnapshot.empty) {
        const conversationDoc = querySnapshot.docs[0];
        await updateDoc(doc(db, 'conversations', conversationDoc.id), {
          lastMessage: content,
          lastMessageTimestamp: timestamp,
          updatedAt: timestamp,
          messagesCount: increment(1)
        });
      }
    } catch (error) {
      console.error('Error adding bot message:', error);
    }
  };

  return (
    <>
      <div className="fixed bottom-4 right-4 z-50">
        {!isOpen ? (
          <motion.button
            onClick={() => setIsOpen(true)}
            className="bg-[#E5C13B] p-4 rounded-full shadow-lg hover:bg-[#E5C13B]/90 transition-colors"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <MessageCircle className="w-6 h-6 text-white" />
            <span className="absolute -top-2 -right-2 w-4 h-4 bg-green-500 rounded-full border-2 border-white"></span>
          </motion.button>
        ) : (
          <motion.div
            initial={{ scale: 0, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            className="bg-gradient-to-b from-gray-900/95 to-black/95 backdrop-blur-lg rounded-2xl shadow-xl w-[90vw] md:w-[450px] max-h-[80vh] md:max-h-[700px] flex flex-col border border-white/10"
          >
            {/* Header */}
            <div className="p-4 md:p-6 border-b border-white/10 flex items-center justify-between bg-black/40">
              <div className="flex items-center space-x-2 md:space-x-4">
                <div className="w-8 h-8 md:w-12 md:h-12 rounded-full bg-[#E5C13B]/20 flex items-center justify-center border-2 border-[#E5C13B] overflow-hidden">
                  <Headphones className="w-6 h-6 text-[#E5C13B]" />
                </div>
                <div>
                  <h3 className="text-lg md:text-xl font-semibold text-white">Assistant Helite</h3>
                  <p className="text-xs md:text-sm text-[#E5C13B]">En ligne</p>
                </div>
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className="p-3 hover:bg-white/10 rounded-full transition-colors duration-200"
              >
                <X className="w-6 h-6 text-white" />
              </button>
            </div>

            {/* Login Form or Chat Content */}
            {!isAuthenticated ? (
              <div className="flex-1 p-6 flex flex-col justify-center bg-gradient-to-b from-black/50 to-transparent">
                <div className="mb-8 flex flex-col items-center">
                  <div className="w-20 h-20 rounded-full overflow-hidden mb-4 border-2 border-[#E5C13B]">
                    <Headphones className="w-10 h-10 text-[#E5C13B]" />
                  </div>
                  <h2 className="text-xl font-semibold text-white mb-2">Discutez avec Marie</h2>
                  <p className="text-gray-400 text-center">Votre conseillère Helite est là pour vous aider</p>
                </div>
                <form onSubmit={handleLogin} className="space-y-6">
                  <div className="space-y-4">
                    <div className="relative">
                      <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#E5C13B] w-5 h-5" />
                      <input
                        type="text"
                        value={userInfo.name}
                        onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
                        placeholder="Votre nom"
                        className="w-full pl-10 pr-4 py-3 bg-white/5 text-white placeholder-gray-400 rounded-lg border border-white/10 focus:outline-none focus:ring-2 focus:ring-[#E5C13B]/50"
                      />
                    </div>
                    <div className="relative">
                      <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-[#E5C13B] w-5 h-5" />
                      <input
                        type="email"
                        value={userInfo.email}
                        onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                        placeholder="Votre email"
                        className="w-full pl-10 pr-4 py-3 bg-white/5 text-white placeholder-gray-400 rounded-lg border border-white/10 focus:outline-none focus:ring-2 focus:ring-[#E5C13B]/50"
                      />
                    </div>
                  </div>
                  {formError && (
                    <p className="text-red-500 text-sm mt-2">{formError}</p>
                  )}
                  <button
                    type="submit"
                    className="w-full bg-[#E5C13B] text-white py-3 px-4 rounded-lg hover:bg-[#E5C13B]/90 transition-colors duration-200 flex items-center justify-center space-x-2"
                  >
                    <span>Commencer la discussion</span>
                    <MessageCircle className="w-5 h-5" />
                  </button>
                </form>
              </div>
            ) : (
              <>
                {/* Messages Container */}
                <div className="flex-1 overflow-y-auto p-4 md:p-6 space-y-4 scrollbar-thin scrollbar-thumb-white/10 scrollbar-track-transparent">
                  <AnimatePresence mode="popLayout">
                    {messages.map((message) => (
                      <motion.div
                        key={message.id}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        layout
                        className={`flex items-start space-x-2 mb-4 ${
                          message.isBot ? 'flex-row' : 'flex-row-reverse'
                        }`}
                      >
                        <div className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                          message.isBot ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-600'
                        }`}>
                          {message.isBot ? <Headphones className="w-5 h-5" /> : <User className="w-5 h-5" />}
                        </div>
                        <motion.div
                          layout
                          className={`mx-2 px-4 py-2 rounded-2xl ${
                            message.isBot
                              ? 'bg-[#E5C13B]/10 text-white'
                              : 'bg-white/10 text-white'
                          }`}
                        >
                          {message.content}
                        </motion.div>
                      </motion.div>
                    ))}
                  </AnimatePresence>
                  {isTyping && (
                    <div className="flex items-center space-x-3">
                      <div className="w-10 h-10 rounded-full border-2 border-[#E5C13B] bg-[#E5C13B]/20 overflow-hidden">
                        <Headphones className="w-6 h-6 text-[#E5C13B]" />
                      </div>
                      <div className="bg-[#E5C13B]/10 px-5 py-3 rounded-2xl">
                        <div className="flex space-x-2">
                          <div className="w-2.5 h-2.5 bg-[#E5C13B] rounded-full animate-bounce"></div>
                          <div className="w-2.5 h-2.5 bg-[#E5C13B] rounded-full animate-bounce delay-100"></div>
                          <div className="w-2.5 h-2.5 bg-[#E5C13B] rounded-full animate-bounce delay-200"></div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>

                {/* Message Input */}
                <div className="p-4 md:p-6 border-t border-white/10 bg-black/40">
                  <form onSubmit={handleSubmit} className="flex items-center space-x-2 md:space-x-3">
                    <input
                      type="text"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      placeholder="Écrivez votre message..."
                      className="flex-1 bg-white/5 text-white placeholder-gray-400 rounded-full px-4 md:px-6 py-2 md:py-3 focus:outline-none focus:ring-2 focus:ring-[#E5C13B]/50 border border-white/10"
                    />
                    <button
                      type="submit"
                      disabled={!newMessage.trim() || isTyping}
                      className="p-3 rounded-full bg-[#E5C13B] hover:bg-[#E5C13B]/90 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
                    >
                      <Send className="w-6 h-6 text-white" />
                    </button>
                  </form>
                </div>
              </>
            )}
          </motion.div>
        )}
      </div>
    </>
  );
};

export default Chat;
