import React, { useState, useEffect } from 'react';
import { Modal } from '../components/ui/modal';
import { Mail, Lock, X } from 'lucide-react';
import { motion } from 'framer-motion';
import Toast from '../components/ui/toast';

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function AuthModal({ isOpen, onClose }: AuthModalProps) {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setEmail('');
      setPassword('');
      setShowToast(false);
    }
  }, [isOpen]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowToast(true);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="fixed inset-0 bg-black/70 backdrop-blur-sm z-50 flex items-center justify-center p-4">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="bg-black/80 backdrop-blur-md p-6 rounded-xl w-full max-w-md border border-yellow-500/20 shadow-xl"
          >
            <div className="relative">
              <button
                onClick={onClose}
                className="absolute right-0 top-0 text-gray-400 hover:text-yellow-500 transition-colors"
              >
                <X className="h-6 w-6" />
              </button>
              
              <h2 className="text-2xl font-semibold mb-6 bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent">
                {isLogin ? 'Se connecter' : 'Créer un compte'}
              </h2>

              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Email
                  </label>
                  <div className="relative">
                    <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      className="w-full pl-10 pr-4 py-2 bg-black/50 border border-yellow-500/20 rounded-lg focus:ring-2 focus:ring-yellow-500/40 focus:border-yellow-500/40 text-gray-100 placeholder-gray-500"
                      placeholder="votre@email.com"
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-300 mb-1">
                    Mot de passe
                  </label>
                  <div className="relative">
                    <Lock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
                    <input
                      type="password"
                      value={password}
                      onChange={handlePasswordChange}
                      className="w-full pl-10 pr-4 py-2 bg-black/50 border border-yellow-500/20 rounded-lg focus:ring-2 focus:ring-yellow-500/40 focus:border-yellow-500/40 text-gray-100 placeholder-gray-500"
                      placeholder="••••••••"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="w-full py-2 px-4 bg-gradient-to-r from-yellow-500 to-yellow-600 hover:from-yellow-600 hover:to-yellow-700 text-black font-medium rounded-lg transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98] shadow-lg hover:shadow-yellow-500/20"
                >
                  {isLogin ? 'Se connecter' : 'Créer un compte'}
                </button>

                <div className="mt-4 text-center">
                  <button
                    type="button"
                    onClick={() => {
                      setIsLogin(!isLogin);
                    }}
                    className="text-sm text-gray-400 hover:text-yellow-500 transition-colors"
                  >
                    {isLogin ? "Pas encore de compte ? S'inscrire" : 'Déjà un compte ? Se connecter'}
                  </button>
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </Modal>

      <Toast
        message="Cette fonctionnalité sera bientôt disponible. Merci de votre patience !"
        type="success"
        isVisible={showToast}
        onClose={() => setShowToast(false)}
      />
    </>
  );
}