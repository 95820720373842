import React from 'react';
import { motion } from 'framer-motion';
import { Star, Quote, MessageCircle } from 'lucide-react';
import ParticleBackground from './ParticleBackground';

const testimonials = [
  {
    name: 'Sophie Martin',
    role: 'Directrice Marketing',
    content: "Un service exceptionnel ! La ponctualité et le professionnalisme du chauffeur ont dépassé mes attentes. Je recommande vivement Elite Transfer pour tous vos déplacements professionnels.",
    rating: 5,
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1974&auto=format&fit=crop'
  },
  {
    name: 'Thomas Dubois',
    role: 'Entrepreneur',
    content: "Je fais régulièrement appel à Elite Transfer pour mes trajets aéroport. La qualité du service est constante et le confort des véhicules est remarquable.",
    rating: 5,
    image: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=2070&auto=format&fit=crop'
  },
  {
    name: 'Marie Lambert',
    role: 'Directrice Événementiel',
    content: "Pour l'organisation de nos événements d'entreprise, Elite Transfer est un partenaire fiable. Leur flexibilité et leur service client sont incomparables.",
    rating: 5,
    image: 'https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=1961&auto=format&fit=crop'
  }
];

export default function Testimonials() {
  return (
    <section id="testimonials" className="relative py-20 bg-[#121212] overflow-hidden">
      {/* Particules en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none">
        <ParticleBackground />
      </div>

      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>

      {/* Effet de lueur */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.4, 1, 0.4] }}
        transition={{ duration: 3, repeat: Infinity }}
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm pointer-events-none"
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          {/* Icône animée */}
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            className="inline-block mb-4"
          >
            <div className="relative">
              <MessageCircle className="w-16 h-16 text-yellow-500" strokeWidth={1.5} />
              <motion.div
                className="absolute inset-0"
                animate={{ 
                  scale: [1, 1.2, 1],
                  opacity: [0.5, 0, 0.5]
                }}
                transition={{
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <MessageCircle className="w-16 h-16 text-yellow-500" strokeWidth={1.5} />
              </motion.div>
            </div>
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600 mb-4"
          >
            Ils nous font confiance
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 max-w-2xl mx-auto"
          >
            Découvrez ce que nos clients disent de notre service de transport haut de gamme
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              whileHover={{ y: -5 }}
              className="relative group"
            >
              {/* Effet de lueur au hover */}
              <div className="absolute -inset-px rounded-2xl bg-gradient-to-r from-yellow-500/50 via-yellow-500/50 to-yellow-600/50 opacity-0 group-hover:opacity-100 transition-opacity blur-sm"></div>

              <div className="relative p-8 rounded-2xl bg-[#1E1E1E]/80 backdrop-blur-xl border border-yellow-500/20 hover:border-yellow-500/50 transition-all h-full">
                {/* Icône de citation */}
                <div className="absolute -top-4 -right-4 w-8 h-8 bg-yellow-500 rounded-full flex items-center justify-center">
                  <Quote className="w-4 h-4 text-black" />
                </div>

                {/* Étoiles */}
                <div className="flex mb-6">
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <Star 
                      key={i} 
                      className="w-5 h-5 text-yellow-500 fill-yellow-500 mr-1"
                    />
                  ))}
                </div>

                {/* Contenu */}
                <blockquote className="mb-6">
                  <p className="text-gray-300 italic leading-relaxed">"{testimonial.content}"</p>
                </blockquote>

                {/* Auteur */}
                <div className="flex items-center">
                  <div className="relative">
                    <div className="w-12 h-12 rounded-full overflow-hidden border-2 border-yellow-500/50">
                      <img
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-full h-full object-cover blur-[4px]"
                      />
                    </div>
                    <motion.div
                      className="absolute -inset-1 rounded-full bg-yellow-500/20 blur-sm"
                      animate={{
                        scale: [1, 1.1, 1],
                        opacity: [0.5, 0.3, 0.5]
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    />
                  </div>
                  <div className="ml-4">
                    <div className="font-semibold text-white">{testimonial.name}</div>
                    <div className="text-sm text-gray-400">{testimonial.role}</div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
