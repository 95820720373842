import React from 'react';
import { motion } from 'framer-motion';
import { Facebook, Instagram, Twitter, Phone, Mail, MapPin } from 'lucide-react';
import ParticleBackground from './ParticleBackground';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-[#121212] text-gray-300 w-full">
      {/* Particules en arrière-plan */}
      <div className="absolute inset-0 h-full overflow-hidden">
        <ParticleBackground />
      </div>

      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>

      {/* Effet de lueur */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.4, 1, 0.4] }}
        transition={{ duration: 3, repeat: Infinity }}
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm pointer-events-none"
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8 py-12">
          {/* À propos */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="space-y-4"
          >
            <h3 className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Elite Transfert
            </h3>
            <p className="text-gray-400">
              Service de transport VTC premium pour tous vos déplacements. Confort, sécurité et ponctualité garantis.
            </p>
            <div className="flex items-center space-x-4">
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-yellow-500 transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-yellow-500 transition-colors"
              >
                <Instagram className="w-6 h-6" />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-yellow-500 transition-colors"
              >
                <Twitter className="w-6 h-6" />
              </a>
            </div>
          </motion.div>

          {/* Services */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Nos Services
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/services/transport" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Transport VTC
                </Link>
              </li>
              <li>
                <Link to="/services/airport" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Transfert Aéroport
                </Link>
              </li>
              <li>
                <Link to="/services/events" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Événements
                </Link>
              </li>
              <li>
                <Link to="/services/business" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Transport Professionnel
                </Link>
              </li>
            </ul>
          </motion.div>

          {/* Contact */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Contact
            </h3>
            <ul className="space-y-2">
              <li>
                <a 
                  href="tel:+33650288464" 
                  className="text-gray-400 hover:text-yellow-500 transition-colors flex items-center gap-2"
                >
                  <Phone className="w-5 h-5" />
                  06 50 28 84 64
                </a>
              </li>
              <li>
                <a 
                  href="mailto:contact@elitetransfert.com" 
                  className="text-gray-400 hover:text-yellow-500 transition-colors flex items-center gap-2"
                >
                  <Mail className="w-5 h-5" />
                  contact@elitetransfert.com
                </a>
              </li>
              <li className="flex items-center gap-2 text-gray-400">
                <MapPin className="w-5 h-5" />
                Paris, France
              </li>
            </ul>
          </motion.div>

          {/* Légal */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.3 }}
            className="space-y-4"
          >
            <h3 className="text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-600">
              Informations
            </h3>
            <ul className="space-y-2">
              <li>
                <Link to="/mentions-legales" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Mentions Légales
                </Link>
              </li>
              <li>
                <Link to="/rgpd" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  RGPD
                </Link>
              </li>
              <li>
                <Link to="/politique-cookies" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  Politique des Cookies
                </Link>
              </li>
              <li>
                <Link to="/cgv" className="text-gray-400 hover:text-yellow-500 transition-colors">
                  CGV
                </Link>
              </li>
            </ul>
          </motion.div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4 text-gray-400 text-sm">
            <p>&copy; {currentYear} Elite Transfert. Tous droits réservés.</p>
            <a 
              href="https://bytebloom.fr" 
              target="_blank" 
              rel="noopener noreferrer" 
              className="hover:text-yellow-500 transition-colors"
            >
              Développé par bytebloom.fr
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}