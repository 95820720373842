import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './styles/main.css';  // Import our cross-browser styles
import './styles/mobile-optimizations.css';  // Ajout des optimisations mobiles
import emailjs from '@emailjs/browser';

emailjs.init('_yxr_4k4q1N2ozuJT');

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js', {
      scope: '/'
    }).then(registration => {
      console.log('SW registered:', registration);
      
      // Vérifier si c'est un appareil iOS
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      if (isIOS) {
        // Forcer le rafraîchissement du service worker sur iOS
        registration.update();
        
        // Gérer le scroll sur iOS
        document.addEventListener('touchmove', (e) => {
          if (document.querySelector('.modal-open')) {
            e.preventDefault();
          }
        }, { passive: false });
      }
    }).catch(registrationError => {
      console.log('SW registration failed:', registrationError);
    });
  });
}

// Désactiver le zoom sur iOS
document.addEventListener('gesturestart', (e) => {
  e.preventDefault();
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)