// Fix pour la hauteur du viewport sur iOS
export const initViewportHeight = () => {
  const setVH = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  // Set initial viewport height
  setVH();

  // Update on resize and orientation change
  window.addEventListener('resize', setVH);
  window.addEventListener('orientationchange', () => {
    // Petit délai pour laisser le temps à iOS de mettre à jour la hauteur
    setTimeout(setVH, 100);
  });
};
