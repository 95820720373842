import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Clock, Award, Users } from 'lucide-react';

const features = [
  {
    icon: Shield,
    title: 'Sécurité Maximale',
    description: 'Véhicules équipés des dernières technologies de sécurité et chauffeurs hautement qualifiés.'
  },
  {
    icon: Clock,
    title: 'Ponctualité Garantie',
    description: 'Service précis et fiable, nous respectons scrupuleusement vos horaires.'
  },
  {
    icon: Award,
    title: 'Excellence Premium',
    description: 'Un service 5 étoiles pour une expérience de transport incomparable.'
  },
  {
    icon: Users,
    title: 'Service Personnalisé',
    description: 'Une approche sur mesure pour répondre à vos besoins spécifiques.'
  }
];

export default function About() {
  return (
    <section id="about" className="relative py-20 bg-[#121212] overflow-hidden">
      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>
      
      {/* Effet de lueur */}
      <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm"></div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-16 items-center">
          {/* Section texte */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="mb-12 lg:mb-0"
          >
            <h2 className="text-4xl font-bold bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent mb-6">
              L'Excellence du Transport VTC
            </h2>
            
            <p className="text-gray-400 mb-8 text-lg">
              Elite Chauffeur incarne l'excellence dans le transport VTC de luxe. Notre engagement envers la qualité et le service personnalisé nous distingue comme le choix privilégié pour vos déplacements haut de gamme à Paris et en Île-de-France.
            </p>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.1 }}
                  className="relative group"
                >
                  <div className="p-4 bg-[#1E1E1E] rounded-xl border border-yellow-500/20 group-hover:border-yellow-500/50 transition-colors">
                    <div className="w-12 h-12 bg-gradient-to-br from-yellow-500/20 to-transparent rounded-lg flex items-center justify-center mb-4 group-hover:scale-110 transition-transform duration-300">
                      <feature.icon className="w-6 h-6 text-yellow-500" />
                    </div>
                    <h3 className="text-lg font-semibold text-white mb-2 group-hover:text-yellow-500 transition-colors">
                      {feature.title}
                    </h3>
                    <p className="text-gray-400 text-sm">
                      {feature.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Section image */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="relative"
          >
            <div className="relative rounded-xl overflow-hidden group">
              <img
                src="/events/corporate.jpg.jpg"
                alt="Service VTC Premium"
                className="w-full h-[600px] object-cover transition-transform duration-700 group-hover:scale-110"
              />
              
              {/* Overlay avec dégradé */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 via-black/30 to-transparent"></div>
              
              {/* Statistiques */}
              <div className="absolute bottom-0 left-0 right-0 p-8 grid grid-cols-2 gap-4">
                <div className="text-center">
                  <div className="text-3xl font-bold text-yellow-500 mb-1">15+</div>
                  <div className="text-sm text-gray-300">Années d'Expérience</div>
                </div>
                <div className="text-center">
                  <div className="text-3xl font-bold text-yellow-500 mb-1">24/7</div>
                  <div className="text-sm text-gray-300">Disponibilité</div>
                </div>
              </div>

              {/* Bordure brillante */}
              <div className="absolute inset-0 border border-yellow-500/20 rounded-xl group-hover:border-yellow-500/50 transition-colors"></div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
