import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, CheckCircle, AlertCircle } from 'lucide-react';

interface ToastProps {
  message: string;
  type: 'success' | 'error';
  isVisible: boolean;
  onClose: () => void;
}

export default function Toast({ message, type, isVisible, onClose }: ToastProps) {
  const bgColor = type === 'success' ? 'bg-[#1E1E1E]' : 'bg-[#1E1E1E]';
  const borderColor = type === 'success' ? 'border-yellow-500/50' : 'border-red-500/50';
  const iconColor = type === 'success' ? 'text-yellow-500' : 'text-red-500';

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 0, y: 50, x: '-50%' }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 ${bgColor} backdrop-blur-lg
            border ${borderColor} rounded-lg shadow-lg px-6 py-4 min-w-[300px] z-50
            flex items-center justify-between`}
        >
          <div className="flex items-center space-x-3">
            {type === 'success' ? (
              <CheckCircle className={`w-5 h-5 ${iconColor}`} />
            ) : (
              <AlertCircle className={`w-5 h-5 ${iconColor}`} />
            )}
            <p className="text-gray-200">{message}</p>
          </div>
          <button
            onClick={onClose}
            className="ml-4 text-gray-400 hover:text-gray-200 transition-colors"
          >
            <X className="w-4 h-4" />
          </button>

          <motion.div
            className="absolute bottom-0 left-0 h-1 bg-gradient-to-r from-yellow-500 to-yellow-600"
            initial={{ width: '100%' }}
            animate={{ width: '0%' }}
            transition={{ duration: 3, ease: 'linear' }}
            onAnimationComplete={onClose}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
