import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/shim/with-selector';

interface AuthState {
  user: any | null;
  isAuthenticated: boolean;
  userRole: string | null;
  loading: boolean;
  error: string | null;
  setUser: (user: any | null) => void;
  setAuthenticated: (value: boolean) => void;
  setUserRole: (role: string | null) => void;
  setLoading: (value: boolean) => void;
  setError: (error: string | null) => void;
  reset: () => void;
}

const initialState = {
  user: null,
  isAuthenticated: false,
  userRole: null,
  loading: true,
  error: null
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      ...initialState,
      setUser: (user: any | null) => {
        console.log('Setting user:', user);
        set({ user, isAuthenticated: !!user });
      },
      setAuthenticated: (value: boolean) => {
        console.log('Setting authenticated:', value);
        set({ isAuthenticated: value });
      },
      setUserRole: (role: string | null) => {
        console.log('Setting user role:', role);
        set({ userRole: role });
      },
      setLoading: (value: boolean) => {
        console.log('Setting loading:', value);
        set({ loading: value });
      },
      setError: (error: string | null) => {
        console.log('Setting error:', error);
        set({ error });
      },
      reset: () => {
        console.log('Resetting auth store');
        set(initialState);
      }
    }),
    {
      name: 'auth-storage',
      version: 1,
      onRehydrateStorage: () => (state) => {
        console.log('Hydrated auth state:', state);
      }
    }
  )
);

// Selector hook with use-sync-external-store
export const useAuthSelector = <T>(selector: (state: AuthState) => T): T => {
  return useSyncExternalStoreWithSelector(
    useAuthStore.subscribe,
    useAuthStore.getState,
    useAuthStore.getState,
    selector
  );
};