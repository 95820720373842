export const APP_CONFIG = {
  // Configuration Firebase
  firebase: {
    apiKey: "AIzaSyApOXofS_kdyt5GbJz5ynl5LdDi2nQN-Mg",
    authDomain: "elite-transfert.firebaseapp.com",
    projectId: "elite-transfert",
    storageBucket: "elite-transfert.firebasestorage.app",
    messagingSenderId: "864370147342",
    appId: "1:864370147342:web:43c3e68a51bfc111cbbc5f",
    measurementId: "G-CRVBQB6PTY",
    region: 'europe-west1'
  },
  
  // Configuration EmailJS
  emailjs: {
    serviceId: "service_a5iuc9w",
    templateId: "template_nuiphap",
    publicKey: "_yxr_4k4q1N2ozuJT",
  },
  
  // Configuration Maps
  maps: {
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '',
    defaultCenter: {
      lat: 48.8566,
      lng: 2.3522,
    },
    defaultZoom: 13,
  },
  
  // Configuration de l'application
  app: {
    name: 'Elite Transfert',
    version: '1.0.0',
    contactEmail: 'contact@elitetransfert.com',
    supportPhone: '+33 1 23 45 67 89',
    defaultLanguage: 'fr',
    defaultTheme: 'light',
    maxUploadSize: 5 * 1024 * 1024, // 5MB
  },
  
  // Email de l'administrateur pour les notifications
  adminEmail: 'contact@elitetransfert.com',
  
  // Configuration des performances
  performance: {
    // Taille des pages pour la pagination
    pageSize: 10,
    // Délai de debounce pour les recherches (ms)
    searchDebounce: 300,
    // Nombre maximum d'éléments à charger en une fois
    maxBatchSize: 50,
    // Durée du cache (ms)
    cacheDuration: 5 * 60 * 1000, // 5 minutes
  },
  
  // Configuration des notifications
  notifications: {
    // Durée d'affichage des toasts (ms)
    toastDuration: 3000,
    // Nombre maximum de notifications affichées
    maxNotifications: 5,
    // Intervalle de vérification des nouvelles notifications (ms)
    pollInterval: 30000,
  },
} as const;

export type AppConfig = typeof APP_CONFIG;

// Helper pour accéder à la configuration de manière type-safe
export function getConfig<K extends keyof AppConfig>(key: K): AppConfig[K] {
  return APP_CONFIG[key];
}
