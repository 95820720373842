import React, { useState, useEffect } from 'react';
import { ArrowUp } from 'lucide-react';

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Optimisation du scroll pour iOS
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      // Utilisation de window.pageYOffset pour une meilleure compatibilité iOS
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility, { passive: true });

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  return isVisible ? (
    <button
      onClick={scrollToTop}
      className="fixed bottom-4 right-4 z-50 p-3 rounded-full bg-yellow-500 text-black shadow-lg transition-all duration-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50 active:transform active:scale-95"
      style={{
        WebkitTapHighlightColor: 'transparent',
        transform: 'translateZ(0)',
        WebkitTransform: 'translateZ(0)'
      }}
      aria-label="Retour en haut"
    >
      <ArrowUp className="w-6 h-6" />
    </button>
  ) : null;
};

export default ScrollToTop;
