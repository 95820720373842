import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from './context/ThemeContext';
import { HelmetProvider } from 'react-helmet-async';
import AuthProvider from './components/AuthProvider';
import Layout from './components/Layout';
import ScrollToTop from './components/ScrollToTop';
import AppRoutes from './routes/AppRoutes';
import { initViewportHeight } from './utils/iosViewportFix';

function App() {
  useEffect(() => {
    // Initialize viewport height fix for iOS
    initViewportHeight();
  }, []);

  return (
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          <AuthProvider>
            <ScrollToTop />
            <Layout>
              <Toaster position="top-center" />
              <AppRoutes />
            </Layout>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;