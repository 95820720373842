import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Phone, MapPin, Send, Clock } from 'lucide-react';
import ParticleBackground from './ParticleBackground';
import Toast from './ui/Toast';
import LoadingSpinner from './ui/LoadingSpinner';
import emailjs from '@emailjs/browser';
import { APP_CONFIG } from '../config/appConfig';

interface FormData {
  name: string;
  email: string;
  subject: string;
  message: string;
}

interface FormErrors {
  name?: string;
  email?: string;
  subject?: string;
  message?: string;
}

export default function Contact() {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: 'success' | 'error'; visible: boolean }>({
    message: '',
    type: 'success',
    visible: false
  });

  const validateForm = useCallback(() => {
    const errors: FormErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!formData.name.trim()) {
      errors.name = 'Le nom est requis';
    }

    if (!formData.email.trim()) {
      errors.email = 'L\'email est requis';
    } else if (!emailRegex.test(formData.email)) {
      errors.email = 'Email invalide';
    }

    if (!formData.subject.trim()) {
      errors.subject = 'Le sujet est requis';
    }

    if (!formData.message.trim()) {
      errors.message = 'Le message est requis';
    } else if (formData.message.length < 10) {
      errors.message = 'Le message doit contenir au moins 10 caractères';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  }, [formData]);

  const showToast = (message: string, type: 'success' | 'error') => {
    setToast({ message, type, visible: true });
    // Fermer automatiquement après 5 secondes
    setTimeout(() => {
      setToast(prev => ({ ...prev, visible: false }));
    }, 5000);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Début de la soumission du formulaire');
    
    if (!validateForm()) {
      showToast('Veuillez corriger les erreurs dans le formulaire', 'error');
      return;
    }

    setIsSubmitting(true);
    console.log('Envoi des données:', formData);

    try {
      const response = await emailjs.send(
        APP_CONFIG.emailjs.serviceId,
        APP_CONFIG.emailjs.templateId,
        {
          from_name: formData.name,
          from_email: formData.email,
          subject: formData.subject,
          message: formData.message
        },
        APP_CONFIG.emailjs.publicKey
      );
      
      console.log('Réponse EmailJS:', response);
      showToast('Votre message a été envoyé avec succès ! Nous vous répondrons dans les plus brefs délais.', 'success');

      // Réinitialiser le formulaire
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: ''
      });
    } catch (error: any) {
      console.error('Erreur détaillée lors de l\'envoi:', error);
      
      let errorMessage = 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer.';
      
      if (error?.status === 400) {
        if (error?.text?.includes('template ID')) {
          errorMessage = 'Erreur de configuration du template. Veuillez contacter le support.';
        } else if (error?.text?.includes('service ID')) {
          errorMessage = 'Erreur de configuration du service. Veuillez contacter le support.';
        }
      } else if (error?.status === 412) {
        errorMessage = 'Erreur d\'authentification du service email. Veuillez contacter le support.';
      }
      
      showToast(errorMessage, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [id]: value
    }));
    // Effacer l'erreur du champ modifié
    if (formErrors[id as keyof FormErrors]) {
      setFormErrors(prev => ({
        ...prev,
        [id]: undefined
      }));
    }
  };

  return (
    <section id="contact" className="relative py-20 bg-[#121212] overflow-hidden">
      {/* Particules en arrière-plan avec pointer-events-none */}
      <div className="absolute inset-0 pointer-events-none">
        <ParticleBackground />
      </div>
      
      {/* Grille futuriste en arrière-plan */}
      <div className="absolute inset-0 pointer-events-none bg-[linear-gradient(to_right,#232323_1px,transparent_1px),linear-gradient(to_bottom,#232323_1px,transparent_1px)] bg-[size:24px_24px] opacity-30"></div>
      
      {/* Effet de lueur */}
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: [0.4, 1, 0.4] }}
        transition={{ duration: 3, repeat: Infinity }}
        className="absolute top-0 left-1/2 -translate-x-1/2 w-[70%] h-1 bg-gradient-to-r from-transparent via-yellow-500 to-transparent blur-sm pointer-events-none"
      />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="relative inline-block"
          >
            <h2 className="text-4xl font-bold bg-gradient-to-r from-yellow-500 to-yellow-600 bg-clip-text text-transparent mb-4">
              Contactez-nous
            </h2>
            <motion.div
              className="absolute -inset-x-6 -inset-y-4 bg-yellow-500/20 blur-xl rounded-full"
              animate={{
                opacity: [0.5, 0.3, 0.5],
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
              }}
            />
          </motion.div>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 max-w-2xl mx-auto"
          >
            Notre équipe est à votre disposition 24h/24 et 7j/7 pour répondre à toutes vos demandes
          </motion.p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Formulaire */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="relative"
          >
            {/* Effet de lueur derrière le formulaire */}
            <div className="absolute -inset-x-20 -top-20 -bottom-20 bg-gradient-to-r from-yellow-500/0 via-yellow-500/5 to-yellow-500/0 transform rotate-12 blur-3xl pointer-events-none"></div>
            
            {/* Formulaire avec z-index pour le mettre au premier plan */}
            <div className="relative z-10 bg-[#1E1E1E]/80 backdrop-blur-xl p-8 rounded-2xl border border-yellow-500/20 hover:border-yellow-500/50 transition-colors">
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                  <div>
                    <label htmlFor="name" className="block text-gray-400 mb-2">
                      Nom
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        id="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full px-4 py-3 bg-black/50 border ${
                          formErrors.name ? 'border-red-500' : 'border-yellow-500/20'
                        } rounded-lg focus:outline-none focus:border-yellow-500 text-gray-300 placeholder-gray-500 transition-colors`}
                        placeholder="Votre nom"
                      />
                      {formErrors.name && (
                        <motion.p
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="absolute text-sm text-red-500 mt-1"
                        >
                          {formErrors.name}
                        </motion.p>
                      )}
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-gray-400 mb-2">
                      Email
                    </label>
                    <div className="relative">
                      <input
                        type="email"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full px-4 py-3 bg-black/50 border ${
                          formErrors.email ? 'border-red-500' : 'border-yellow-500/20'
                        } rounded-lg focus:outline-none focus:border-yellow-500 text-gray-300 placeholder-gray-500 transition-colors`}
                        placeholder="votre@email.com"
                      />
                      {formErrors.email && (
                        <motion.p
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="absolute text-sm text-red-500 mt-1"
                        >
                          {formErrors.email}
                        </motion.p>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <label htmlFor="subject" className="block text-gray-400 mb-2">
                    Sujet
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      className={`w-full px-4 py-3 bg-black/50 border ${
                        formErrors.subject ? 'border-red-500' : 'border-yellow-500/20'
                      } rounded-lg focus:outline-none focus:border-yellow-500 text-gray-300 placeholder-gray-500 transition-colors`}
                      placeholder="Sujet de votre message"
                    />
                    {formErrors.subject && (
                      <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="absolute text-sm text-red-500 mt-1"
                      >
                        {formErrors.subject}
                      </motion.p>
                    )}
                  </div>
                </div>

                <div>
                  <label htmlFor="message" className="block text-gray-400 mb-2">
                    Message
                  </label>
                  <div className="relative">
                    <textarea
                      id="message"
                      value={formData.message}
                      onChange={handleChange}
                      rows={4}
                      className={`w-full px-4 py-3 bg-black/50 border ${
                        formErrors.message ? 'border-red-500' : 'border-yellow-500/20'
                      } rounded-lg focus:outline-none focus:border-yellow-500 text-gray-300 placeholder-gray-500 resize-none transition-colors`}
                      placeholder="Votre message..."
                    ></textarea>
                    {formErrors.message && (
                      <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="absolute text-sm text-red-500 mt-1"
                      >
                        {formErrors.message}
                      </motion.p>
                    )}
                  </div>
                </div>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="relative w-full px-8 py-4 bg-gradient-to-r from-yellow-500 to-yellow-600 text-black rounded-lg font-medium hover:from-yellow-600 hover:to-yellow-700 transition-all flex items-center justify-center group disabled:opacity-50"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="flex items-center space-x-2">
                      <LoadingSpinner size="sm" />
                      <span>Envoi en cours...</span>
                    </div>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <span>Envoyer le message</span>
                      <Send className="w-5 h-5 transform group-hover:translate-x-1 transition-transform" />
                    </div>
                  )}
                </motion.button>
              </form>
            </div>
          </motion.div>

          {/* Informations de contact */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="space-y-8"
          >
            {/* Carte avec les infos */}
            <div className="bg-[#1E1E1E]/80 backdrop-blur-xl p-8 rounded-2xl border border-yellow-500/20 hover:border-yellow-500/50 transition-colors">
              <div className="space-y-6">
                <motion.div
                  whileHover={{ x: 5 }}
                  className="flex items-center space-x-4 text-gray-400 hover:text-yellow-500 transition-colors group"
                >
                  <div className="w-12 h-12 bg-yellow-500/10 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform">
                    <Phone className="w-6 h-6 text-yellow-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white">Téléphone</h3>
                    <p>+33 6 50 28 84 64</p>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ x: 5 }}
                  className="flex items-center space-x-4 text-gray-400 hover:text-yellow-500 transition-colors group"
                >
                  <div className="w-12 h-12 bg-yellow-500/10 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform">
                    <Mail className="w-6 h-6 text-yellow-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white">Email</h3>
                    <p>contact@elitetransfert.com</p>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ x: 5 }}
                  className="flex items-center space-x-4 text-gray-400 hover:text-yellow-500 transition-colors group"
                >
                  <div className="w-12 h-12 bg-yellow-500/10 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform">
                    <MapPin className="w-6 h-6 text-yellow-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white">Adresse</h3>
                    <p>Paris, France</p>
                  </div>
                </motion.div>

                <motion.div
                  whileHover={{ x: 5 }}
                  className="flex items-center space-x-4 text-gray-400 hover:text-yellow-500 transition-colors group"
                >
                  <div className="w-12 h-12 bg-yellow-500/10 rounded-lg flex items-center justify-center group-hover:scale-110 transition-transform">
                    <Clock className="w-6 h-6 text-yellow-500" />
                  </div>
                  <div>
                    <h3 className="text-lg font-semibold text-white">Disponibilité</h3>
                    <p>24h/24 - 7j/7</p>
                  </div>
                </motion.div>
              </div>
            </div>

            {/* Carte Google Maps */}
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="relative h-[300px] rounded-2xl overflow-hidden group"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83998.95410688164!2d2.2646332471843833!3d48.85893843540037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1f06e2b70f%3A0x40b82c3688c9460!2sParis%2C%20France!5e0!3m2!1sfr!2sfr!4v1635923991546!5m2!1sfr!2sfr"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                className="grayscale transition-all duration-300 group-hover:grayscale-0"
              ></iframe>
              <div className="absolute inset-0 border border-yellow-500/20 group-hover:border-yellow-500/50 rounded-2xl pointer-events-none transition-colors"></div>
            </motion.div>
          </motion.div>
        </div>
      </div>
      
      {/* Toast notification */}
      <Toast
        message={toast.message}
        type={toast.type}
        isVisible={toast.visible}
        onClose={() => setToast(prev => ({ ...prev, visible: false }))}
      />
    </section>
  );
}
